@import "src/theme/";

.fact-finding-container {
	margin: $space-sm $space-sm;
	max-width: 1551px; // $ft-container-width
	min-height: calc(100vh - 72px);
	display: flex;
	align-items: stretch;
	height: 100%;

	>.ft-split__first {
		max-width: 408px;
		flex: 1;
	}

	>.ft-split__middle {
		width: 645px;
		flex: 2.1;

		@media (min-width: 1200px) and (max-width: 1600px) {
			flex: 2.1;
		}

		.card-header-line {
			display: flex;
			align-items: center;

			.card-header__content {
				padding-left: $space-sm;
				padding-right: $space-xs;

				.header-title {
					text-transform: uppercase;
					font-size: $space-sm;
				}

				.ma-label {
					font-size: $space-sm;
				}

				.last-updatedon {
					font-size: 11px;
					margin-left: $space-xs;
				}

				h3 {
					font-size: $h3-size;
					margin-bottom: 0;
				}
			}

			&__icon {
				font-size: 49px;
			}
		}

		.ft-page-block__y-padded {
			padding-right: $space-sm;
			padding-left: $space-sm;

			@media (min-width: 1500px) {
				padding-right: $space-lg;
				padding-left: $space-lg;
			}
		}
	}

	>.ft-split__last {
		max-width: 408px;
		flex: 1;
	}

	.fact-find-tnc {
		color: $ft-grey-4;
		font-size: $smallest-size;
		margin-top: $space-lg;
	}

	.ftbox-header {
		font-size: $space-sm;
	}
}

// fact-finding-summary-card

.fact-finding-summary-card {
	display: flex;
	max-width: 280px; //343px;
	flex-direction: column;
	border-radius: 20px;
	background: $ft-teal-4;
}

.ff-card__header {
	padding: $space-sm $space-sm;
	color: $ft-white;

	>* {
		margin: 0;
	}

	.card-header-line {
		display: flex;
		align-items: center;

		.card-header__content {
			padding-left: $space-lg;
			padding-right: $space-xs;

			p,
			span {
				font-size: $smallest-size;
				margin: 0;
				line-height: 1.5em;
			}

			h4 {
				font-size: $h3-size;
			}
		}

		&__icon {
			font-size: 49px;
		}
	}
}

.ff-card__body {
	.review-breakdown {
		padding: $space-sm;
		color: $ft-white;

		&-title {
			border-bottom: 1px solid $qf-color-neutral-border;
			padding-bottom: $space-xs;
			margin: 0;
			font-size: $p-size;

			&:not(:first-child) {
				margin-top: $space-sm;
			}
		}

		.label-value__label {
			margin-top: $space-sm;

			&:first-child {
				margin-top: 4px;
			}
		}

		.label-value {
			margin-top: $space-sm;

			&__label-value {
				display: flex;
				margin-top: $space-xs;
			}

			&__label {
				flex-grow: 6.5;
			}

			&__value {
				flex-grow: 3;
				justify-content: end;
				font-weight: 600;
				color: $ft-white !important;
			}

			&__label,
			&__value {
				display: flex;
				flex-basis: 0;
				margin: 0;
				font-size: $p-size;
				color: $qf-color-neutral;
			}
		}
	}
}

// FF Steps

.floating.md {
	width: 21px;
	height: 21px;
	font-size: 30px;
	padding: 1rem;
}

.floating {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.floating.sm {
	width: 25px;
	height: 25px;
	padding: 7px;
}

.step-container {
	@media screen and (min-width: 1200px) and (max-width: 1600px) {
		margin-left: calc(90px + (100vw - 1200px) * (115 - 189) / (1200 - 1600));
	}

	@media (max-width: 1199px) {
		margin-left: 115px;
	}

	@media (min-width: 1601px) {
		margin-left: 150px;
	}

	>.client-info {
		display: flex;
		padding-left: 78px;
		margin: $space-lg 0;
	}

	.step {
		>.icon {
			background-color: $ft-teal-4;
			cursor: pointer;
			border: 1px solid $ft-grey-2;
			color: $ft-grey-2;
			box-shadow: inset 0 0 0 3px $ft-white;
			width: 24px;
			height: 24px;
			font-size: 30px;
			padding: $space-sm;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
		}

		>.icon-edit {
			margin-top: $space-sm;
			margin-right: $space-xs;
			font-size: $h6-size;
		}

		>.step-description {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-left: 8px;

			.step-index {
				font-weight: 700;
			}
		}

		display: flex;
		color: #c6c6c6;
		padding: 0;
		margin: $space-lg 0;
		z-index: 10;
		transform: translateX(10px);
		width: clamp(174px, 14vw, 219px);
		border-radius: 101px 50px 50px 101px;
		box-shadow: 0 0 2px 1px #bbb8b87a;
		background-color: $ft-white;
		cursor: pointer;

		&.completed {
			background-color: $ft-teal-4;
			color: $ft-white;
		}

		&.active {
			background-color: $ft-white;
			color: $ft-grey-4;

			>.icon {
				color: $ft-white;
			}
		}

		&.disabled {
			cursor: not-allowed;
			pointer-events: none;
		}

		&.uncompleted {
			background-color: transparent;
			color: $ft-grey-4;
			box-shadow: none;
			align-items: center;

			>.icon {
				background-color: $ft-white;
				border-width: 2px;
			}
		}
	}
}

// Monthly Annual Toggle Buttons
.period-buttons {
	margin-left: .7rem;
	align-items: center;
	display: flex;

	.MuiButton-root {
		border: 1px solid #345edd;
	}

	button {
		font-size: $p-size;
		padding: 6px $space-sm;
		width: clamp(87px, 7vw, 109px);

		&:hover {
			color: #fff;
			background-color: #345edd;
			box-shadow: 0 4px 10px #21212126;
		}
	}

	.button-container .monthly-toggle {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border-right: none;
	}

	.button-container .annual-toggle {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		border-left: none;
	}

	.button-container {
		padding: 10px 0 0 0;
	}
}

// T&C Container
._review-legal-content {
	color: $ft-black;
	font-size: $smallest-size;
	margin-top: $space-sm;

	p {
		margin: 0 0 0.8em;
		line-height: 1.5em;
	}
}

// Grouped Input Box
.grouped-input-box {
	display: flex;
	width: 80%;
	padding: 1.5rem;
	background-color: #fafafa;
	align-items: baseline;
	border-radius: 20px;
	margin-top: 1rem;

	>.left {
		flex: 71%;

		> :not(:first-child) {
			margin-top: $space-sm;
		}
	}

	>.right {
		flex: 29%;
		display: flex;
		margin: auto;
		justify-content: center;
	}

	>.column {
		align-items: baseline;

		> :not(:last-child) {
			margin-bottom: $space-sm;
		}
	}
}

// FF Cntrols
.ff-controls {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - 145px);
}

.fact-finding-control {
	display: flex;
	gap: 6px;
	// bottom: 0;
	align-self: flex-start;

	.button-container {
		#save-edit-btn {
			padding: $space-xs $space-sm !important;
			width: clamp(133px, 11vw, 163px);
			background-color: $ft-white;
			color: $ft-sky-blue;
			border: 1px solid $ft-sky-blue;
			font-weight: 600;

			&:hover {
				border: 1px solid $ft-sky-blue;
				font-weight: 600;
				color: $ft-sky-blue-4;
				background-color: $ft-secondary-button-hover;
				outline: 1px solid $ft-sky-blue;
				box-shadow: 0 4px 10px #21212126;
			}

			&:disabled {
				background-color: $ft-grey-2;
				color: $ft-black;
				border: 1px solid $ft-grey-2;
				opacity: 1;
				font-weight: 600;
			}
		}

		#next-step-btn {
			padding: $space-xs $space-sm;
		}
	}
}

// Input group

.input-group {
	display: flex;
	gap: $space-sm;

	.ft-switch {
		display: flex;
		flex-direction: column;
		align-items: center;

		.toggle-label {
			margin-top: $space-xs;
			color: $ft-black;
			font-weight: bold;
			font-size: $smallest-size;
			margin-left: 0;
		}

		.toggle {
			margin-left: 0;
		}
	}

	>.left-input-group {
		flex: 35%;

		> :not(:first-child) {
			margin-top: $space-sm;
		}
	}

	>.right-input-group {
		flex: 17%;
		display: flex;
		margin: auto;
		justify-content: center;
	}

	// >.column {
	// 	align-items: baseline;
	// }

	&:not(:first-child) {
		margin-top: $space-lg;
	}
}

.db-pension-card .input-group {
	>.middle-input-group {
		flex: 48%;
		flex-direction: column;
		margin: auto;

		> :not(:first-child) {
			margin-top: $space-sm;
		}
	}
}

// Other income provider
.other-income-provider .input-group {
	.select-field {
		padding: 0;

		.MuiInputLabel-shrink {
			transform: translate(14px, 0px) scale(0.75) !important;
		}
	}

	>.middle-input-group {
		flex: 48%;
		display: flex;
		flex-direction: column;

		> :not(:first-child) {
			margin-top: $space-sm;
		}
	}
}

// .ftbox-body {
// 	.select-field {
// 		padding: 4px 0 2px 0;
// 	}
// 	// padding: 20.5px 14px 12.5px 14px
// 	.MuiInputLabel-shrink {
// 		transform: translate(14px, 0px) scale(0.75) !important;
// 	}
// }

// State Income 
.state-pension-card__amount-container {
	.input-field {
		width: 168px;
	}
}

.expenditure-container {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	margin-top: 1rem;
	align-items: flex-start;

	.ft-switch {
		display: flex;
		flex-direction: row-reverse;
	}
}

.retirement-lifestyle-buttons {
	display: flex;
	gap: 0;
	align-items: center;
	margin-top: -1rem;
}

.retirement-lifestyle-inputs {
	width: 100%;
	columns: auto 2;
	break-inside: avoid;
}

.lifestyle_input_fields {
	display: flex;
	flex-direction: column;

	span {
		margin-top: -12px;
		margin-bottom: 10px;
		padding: 0;
	}
}

// Suitability

.suitability-risk-container {
	display: flex;
	justify-content: space-between;
}

.risk-provider-dropdown {
	margin-top: 0.5rem;
	max-width: 95%;
	align-items: baseline;
	background: #fff;
	border-radius: 0;
	height: auto;
	line-height: 1.3em;
	box-shadow: none;
	transition: background-color .2s cubic-bezier(0, 0, .2, 1) 0ms, box-shadow .2s cubic-bezier(0, 0, .2, 1) 0ms;
}

.risk-profile-label {
	margin-top: $space-sm;
}

._risk-radial {
	padding: $space-lg 0;

	h6 {
		margin: 0;
		margin-top: 2rem;
		color: #278681;
		text-align: center;
		font-size: $space-sm;
		font-weight: 700;
	}
}

._risk-selection-area {
	flex: .9;
}

.toggle-section {
	margin-left: 2rem;
}