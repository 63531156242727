@import "../../shared.scss";

.reminder {
    color: $ft-berry-4;
}

.pdf-content {
    padding-left: $space-lg;
    padding-right: $space-lg;
}

.steps__title-container {
    padding: $space-md $space-lg;
    display: flex;
}

.data-table {
    &__row {
        display: flex;
        padding: $space-sm $space-md;

        &.header {
            text-transform: uppercase;
            border-bottom: 2px solid $ft-brand-primary-blue;
            font-weight: 600;
        }

        &.body {
            border-top: 1px solid $qf-color-secondary;

            &:last-child {
                border-top: 0 solid transparent;
            }
        }

        &.footer {
            border-top: 1px solid $ft-grey-4;

            h6 {
                margin: 0;
            }

            .implementation-plan__footer-bold-label {
                font-weight: 600;
            }
        }

        > div {
            &:nth-child(1) {
                flex-grow: 1.5;
                flex-basis: 0;
            }

            &:nth-child(2) {
                flex-grow: 1;
                flex-basis: 0;
            }

            &:nth-child(3) {
                flex-grow: .75;
                flex-basis: 0;
            }

            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
                flex-grow: .5;
                flex-basis: 0;
            }

            &.align-right {
                text-align: end;
            }
        }
    }
}