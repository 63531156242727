// @import '_defs';
// @import '../shared';
@import "src/theme/";

.portfolio-allocation-container {
    display: flex;
    min-height: 400px;

    .highcharts-chart-container {
        flex: 40%;

        highcharts-chart {
            display: block;
            overflow: hidden;
        }
    }

    .assets-info {
        flex: 60%;

        .asset-class {
            margin-bottom: $space-xl;

            .allocation-header {
                display: flex;
                border-bottom: 3px solid var(--border-color);
                margin-bottom: $space-sm;
                font-weight: bold;
                font-size: $h6-size;

                > :nth-child(1) {
                    flex: 4;
                }

                > :nth-child(2) {
                    flex: 1;
                    text-align: right;
                    padding-right: $space-sm;
                }
            }

            .instrument-columns,
            .instrument {
                display: flex;
                font-size: $p-size;
                gap: $space-md;

                > :nth-child(1) {
                    flex: 2;
                }

                > :nth-child(2) {
                    flex: 1;
                }

                > :nth-child(3) {
                    flex: 1;
                    text-align: right;
                    padding-right: $space-sm;
                }
            }

            .instrument-columns {
                color: $ft-grey-4;
                font-size: $smallest-size;
            }

            .instrument {
                display: flex;
                border-bottom: 1px $ft-grey-3 solid;
                padding: $space-sm 0;
            }

            .toggle-btn {
                float: right;
                margin-top: 18px;
                margin-bottom: 0;
                font-size: $p-size;
                color: $ft-brand-primary-blue;
                cursor: pointer;
            }
        }
    }
}

.proposal-pdf {
    flex-wrap: wrap;

    .highcharts-chart-container {
        height: 400px;
        width: 40%;
        flex: initial;
        margin: auto;

        > * {
            width: 100%;
            display: block;
        }
    }

    .recommend-allocation {
        width: 60%;
        flex: initial;
        margin: auto;
    }

    .assets-info {
        margin-top: $space-md;
        flex: 100%;
        margin-top: 50px;
        @supports (display: grid) {
            column-count: 2;
        }

        @supports not (display: grid) {
            display: flex;
        }

        .asset-class {
            @supports (display: grid) {
                display: grid;
                grid-template-rows: 1fr auto;
                margin-bottom: 10px;
                break-inside: avoid;
            }

            @supports not (display: grid) {
                flex: 50%;
                padding: $space-md;
                flex-flow: row wrap;
            }

            .instrument {
                a {
                    color: $ft-grey-4;
                }
            }
        }
    }
}
