@import "src/theme";

.logout-message {
	margin: auto;
	margin-top: 2rem;
	margin-bottom: -4rem;
	position: relative;
	width: $ft-container-width-small;
}

.main-content {
	display: flex;
	margin: 2rem auto;
	max-width: $ft-container-width;

	.ftbox-container {
		width: calc($ft-container-width-small/2);
	}
}

.middle-content {
	display: flex;
	margin: 5rem auto;
	margin-bottom: 2rem;
	max-width: $ft-container-width;

	.left-section {
		background: rgba(0, 0, 0, 0.02);
		border: 1px solid rgba(0, 0, 0, 0.12);
		padding: 2rem 3.5rem;
		width: 45%;
		margin: 0 3.5rem;
	}

	.right-section {
		width: 55%;

		p {
			margin-bottom: 0;
		}

		.link {
			text-decoration: underline;
		}

		a {
			&:hover {
				color: #33adff;
				text-decoration: underline;
			}
		}
	}
}


.static-content-container {
	width: 50%;
	text-align: center;
	margin: 3rem 1rem 0 5rem;
	padding: 0 1rem;
}

.static-content-heading {
	color: #212121;
	font-size: 3rem;
	line-height: 150%;
	padding: 0 1.25rem;
	margin: 0 0 .8em;
}

.static-content-body {
	line-height: 1.5em;
}

.main-content {
	position: relative;
}

.box-container {
	.image-container {
		position: absolute;
		top: 15%;
		left: 0;
		z-index: -1;

		img {
			padding-top: $space-sm;
			width: clamp(100px, 11vw, 214px);
		}
	}
}

.static-content-icons {
	margin-top: 1.5rem;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.header-icon {
	.ft {
		font-size: 42px;
		color: $ft-brand-primary-blue;
	}

	h6 {
		font-weight: normal;
		padding: $space-xs;
	}
}

.disclaimer-container {
	word-break: break-word;
	color: #212121;
	margin: 0 auto;
	max-width: $ft-container-width;

	p {
		padding: 0 3.5rem;
	}

	a {
		&:hover {
			color: #33adff;
			text-decoration: underline;
		}
	}
}

.disclaimer-info {
	background: $ft-grey-1;

	p {
		padding: 1.5rem 3.5rem;
		max-width: $ft-container-width-small;
		margin: 0 auto;
	}
}

.sitemap-container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	max-width: $ft-container-width-small;
	margin: auto auto 2rem;
	gap: 1.5rem;
	padding: 3.5rem 8rem 1rem;
}

.sitemap-columns {
	display: flex;
	flex-direction: column;

	* {
		margin-bottom: 2rem;
	}
}

.sitemap-columns-heading {
	margin-top: 0;
	color: $ft-primary-light;
	font-size: 1.2rem;
	font-weight: 700;
}

.sitemap-links {
	max-width: 1110px;
	display: flex;
	justify-content: flex-start;
	flex-flow: nowrap;
	flex-direction: row;
	gap: 1rem;
	margin: auto;
	margin-bottom: 1.5rem;
	padding: 0 5rem;

	* {
		justify-content: center;
		margin: auto;
		font-size: 0.813rem;
	}
}

.footer-container {
	display: flex;
	justify-content: space-between;
	background: $ft-primary;
	border-bottom: 2px solid $qf-color-secondary;
	padding: 1.5rem;
	color: $qf-color-secondary;

	p {
		max-width: $ft-container-width-small;
		margin: 0;
	}
}

.ft-footer-logo {
	max-width: 182px;
}