@import "../../theme";

.control-bar {
    display: flex;
    padding: 10px;
    padding-left: 0;

    &__back-drop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    >* {
        flex: 1;
    }

    .ft-toggle:nth-of-type(2) {
        margin-top: 0;
        padding-top: 10px;
    }

    .comparators-btn {
        position: relative;
        padding-left: 32px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .button-container {
            padding: 0;
        }

        .close-button {
            border-radius: 12px;
            background: $ft-sky-blue-1;
            border: 0;
            color: #1446e1;
            font-weight: normal;
            font-size: $smallest-size;

            &:hover {
                background-color: $ft-sky-blue-1 !important;
            }

            button {
                padding: .625rem $space-sm;

                .ft-icon {
                    color: #1446e1 !important;
                    margin-left: .25rem;
                    transform: rotate(90deg);
                    font-size: 1rem;
                    transition: ease 300ms transform;

                    &.opened {
                        transform: rotate(270deg);
                    }
                }
            }
        }

        .comparators-container {
            position: absolute;
            background: $ft-white;
            padding: 32px;
            z-index: 3;
            top: 46px;
            width: 500px;
            box-shadow: 3px 3px 10px 0 #00000059;

            .flex-split {
                gap: 32px;

                >* {
                    padding: 0;
                }
            }

            .ft-switch {
                display: flex;
                align-items: flex-end;
                margin-top: 10px;

                .toggle {
                    margin-left: 0;
                    margin-right: $space-sm;
                }
            }
        }
    }

    .empty-div {
        flex-grow: 0;
    }
}