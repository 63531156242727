@import "../../shared";
@mixin summary-description-style {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 17px 24px;
}

.pdf-content {
  padding: 0 $space-lg;

  &-container {
    width: 1200px;
    margin: auto;
    background: $qf-color-content-background;
  }

  h5, h6 {
    font-weight: 700;
  }
}

.pdf-content-container {
  width: 1200px;
  background-color: $ft-white;
}

.proposal-pdf-modal-panel {
  border: 1px solid #595959;
  max-height: 65vh;
  overflow: auto;
  margin: auto;
  background-color: #bfbfbf;
}

.proposal-pdf-modal {
  position: relative;
  z-index: 1200;
  margin: 5%;
  background-color: $ft-white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  outline: 0;
}

.proposal-pdf-modal-body {
  padding: 30px;
}
.proposal-pdf-modal-close {
  display: flex;
  justify-content: flex-end;
}

.proposal-pdf-modal-download {
  display: flex;
  justify-content: flex-end;
}

.proposal-pdf-icon {
 font-size: 2.375rem;
}
.hidden-pdf-container {
  position: relative;
  top: 0;
  z-index: -1;
}

.proposal-pdf {
  ._results {
    padding: 26px;
    padding-left: 12px;
    background-color: #3769ff;
    color: $ft-white;
    display: flex;
    align-items: center;
    height: 150px;
    gap: 10px;
    border-radius: 20px 20px 0 0;
    margin: 0 2rem;

    &.pdf-summary {
      ._results-proposal-pdf-description {
        @include summary-description-style;

        flex-grow: 4;
      }

      div {
        flex-basis: 0;
        flex-grow: 3;
      }

      .highcharts-container {
        position: relative;
        top: 47px;
        flex: 0 0 316px;

        qf-popover::ng-deep {
          position: absolute;
          top: 47px;
          right: 0;
          height: fit-content;
        }
      }
    }

    div {
      flex-basis: 0;
      flex-grow: 2;
    }

    .k-chart {
      color: $ft-white;
      height: 150px;
      width: 150px;
      font-size: 30px;
      margin: auto;
    }

    ::ng-deep .k-chart-donut-center {
      margin-top: 4px;
    }

    &-income {
      font-size: 48px;
      text-align: center;

      div {
        max-width: 70%;
        margin: auto;
      }

      &__description {
        font-size: $smallest-size;
        border-top: 1px solid $qf-color-neutral-border;
        padding-top: $space-xs;
        margin-top: $space-xs;
        text-align: left;
      }
    }

    highcharts-chart ::ng-deep {
      display: block;
      overflow: hidden;

      h3 {
        margin: 0;
        font-weight: bold;
      }
    }
  }

  .proposal-tab {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $space-sm;
    flex: 1;
    margin: $space-lg 0;

    &:not(:nth-last-child(1)) {
      border-right: 1px solid $ft-grey-3;
    }

    &-container {
      display: flex;
      justify-content: space-around;
    }

    .__title {
      margin: auto 0;
    }

    &__value {
      font-weight: bold;
      margin: auto 0;
    }
  }

  .retirement-income-optimiser {
    &-container {
      display: flex;
      background: $qf-input-group-background;
      justify-content: space-between;
    }

    &__content,
    &__steps {
      flex-basis: 0;
    }

    &__content {
      padding: 3rem 0;
      flex-grow: 0.4;

      p {
        font-weight: bold;
        color: $ft-sky-blue;
        text-transform: uppercase;
      }
    }

    &__steps {
      margin: auto 0 auto auto;
      flex-grow: 0.5;

      &-container {
        display: flex;
        justify-content: flex-end;
      }

      &-left-step {
        display: inline-flex;
        margin-right: $space-md;
      }

      &-right-step {
        display: inline-flex;
        justify-content: space-between;
        flex-direction: column;
        transform: translateY($space-md);

        .retirement-income-optimiser__step:not(:nth-last-child(1)) {
          margin-bottom: $space-sm;
        }
      }
    }

    &__step {
      height: 210px;
      width: clamp(180px, 15vw, 220px);
      border-radius: 16px;
      padding: $space-md;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $ft-white;

      h2 {
        margin: 0;
      }

      &__title {
        margin-bottom: $space-sm;
        font-weight: bold;
      }

      &.step2 {
        background: #44a9a4;
        margin: auto;
      }

      &.step1 {
        background: $ft-sky-blue-3;
      }

      &.step3 {
        background: $ft-sky-blue-4;
      }
    }
  }

  .summary {
    &__expenditure-level {
      display: flex;
      margin: $space-md 0;

      &__label {
        height: fit-content;
        margin: auto 0;
        font-weight: normal;
      }

      &__value {
        font-weight: bold;
        color: $ft-sky-blue-4;
        height: fit-content;
        margin: 0 auto 0 1rem;
      }
    }

    &__annual-income {
      &__container {
        margin: auto;
      }

      &__operator {
        font-size: 6rem;
        color: $ft-brand-primary-blue;
        margin: auto;
      }

      &__card {
        border: 1px rgba(118, 118, 118, 0.2) solid;
        border-radius: 16px;
        padding: $space-sm;
        height: 200px;
        width: 200px;
        background: $qf-color-content-background;
        text-align: center;
        display: flex;
        color: $ft-brand-primary-blue;

        qf-icon {
          font-size: $h1-size;
        }

        &.total {
          background: $ft-dark-blue;
          color: $ft-white;
        }

        &__container {
          margin: auto;
          display: flex;
          flex-direction: column;
        }

        &__value {
          margin-bottom: 0.25rem;
        }

        &__label {
          font-size: 1.2rem;
        }

        &-holder {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
        }
      }
    }
  }

  .portfolio {
    &-description {
      &__title {
        color: $ft-brand-primary-blue;
        font-weight: normal;
      }

      &-container {
        border-bottom: 1px solid $ft-grey-3;
        margin-bottom: $space-sm;

        .portfolio {
          &-investment-objective,
          &-investment-strategy {
            margin-bottom: $space-md;
          }
        }
      }
    }
  }

  .charges {
    &-table-container {
      padding: $space-sm;
      margin: $space-lg;
      background: $qf-color-neutral-background;

      .table {
        margin-bottom: 0;

        thead {
          th {
            border-bottom: 0;
          }
        }

        .charges-item__value {
          padding-right: $space-lg;
        }

        td {
          padding: 8px;
        }

        tfoot {
          td {
            padding-top: $space-sm;
            border-top: 1px solid #8c8c8c52;
          }
        }
      }

      .total-changes {
        &-value,
        &-label {
          font-weight: bold;
        }
      }
    }

    &-item {
      &__date {
        font-size: $smallest-size;
      }

      &__value {
        text-align: right;
      }
    }
  }

  .economic-assumption {
    &__title {
      margin-top: $space-md;
    }
  }

  .statement-facts {
    .__title {
      padding: $space-sm $space-lg;
    }

    &__name {
      padding-top: $space-sm;
      padding-bottom: $space-md;
      margin: 0;
    }

    &__description {
      padding-bottom: $space-md;
    }

    &__row {
      display: flex;
      padding: $space-md;
      margin: 0 $space-lg;
      gap: $space-lg;

      &:nth-child(even) {
        background: $qf-color-neutral-background;
      }
    }

    &__label {
      flex: 60%;
    }

    &__value {
      flex: 40%;
      display: flex;
      justify-content: flex-end;
      text-align: right;
      word-break: break-word;
      max-width: 400px;

      &.personalised-quote {
        display: flex;
        flex-direction: column;
      }

      &.list {
        text-align: left;

        ul {
          list-style-type: circle;

          li:not(:nth-child(1)) {
            padding-bottom: $space-sm;
          }
        }
      }

      &__list-item {
        margin-bottom: $space-sm;
        list-style: none;
      }

      .life-event-item {
        list-style: disc;
      }
    }
  }

  .reminder {
    padding-top: $space-lg;
    color: $ft-disclaimer-text-color;
    font-size: $smallest-size;
  }
  .charges-table-container {
    table {
      thead {
        text-align: left ;
      }
      width: 100%;
    }
  }
}