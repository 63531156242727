@import "../../theme/defs";

.redirect-outside-popup {
    h3 {
        color: $ft-sky-blue;
        font-weight: 500;
    }

    p {
        font-size: $smallest-size;
        margin: 0 0 .8em;
        line-height: 1.5em;
    }
}

.redirect-outside-popup-buttons {
    text-align: right;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}