@import "../../theme/defs";

._card-button {
  display: flex;
  flex: 1;
  cursor: pointer;

  .ft-card {
    &__ui-style-standard {
      border-radius: 0;
      box-shadow: 0 5px 10px #76767626;

      &:hover {
        background: $ft-grey-3;
        flex: 1;
      }
    }

    &__body {
      display: flex;
      padding: $space-md;
      color: $ft-dark-blue;
      gap: $space-md;

      .ft-icon {
        font-size: 42px;
      }

      .health-profile-selection {
        &__title {
          color: $ft-black;
          font-weight: 700;
        }

        &__content {
          color: $ft-black;
          font-size: $smallest-size;
        }
      }
    }
  }
}

.active {
  .ft-card__ui-style-standard {
    background: $ft-brand-primary-blue !important;
    color: $ft-white;

    .health-profile-selection {
      &__title,
      &__content {
        color: $ft-white;
      }
    }
    .ft-icon {
      color: $ft-white;
    }
  }
  i {
    color: $ft-white;
  }
}


// Select Card
.select-card-body {
  padding: .5rem ;
  display: flex;
  flex-direction: column;
}

.select-card-icon {
  color: $ft-brand-primary-blue!important;
  padding-top: 2px;
  i {
    width: 10px!important;
  }
}

.select-card-content {
  margin: 0;
  padding-left: 7px;
}

.select-card-title {
  display: flex;
  i {
    padding: 5px;
    color: $ft-brand-primary-blue;
  }
  h6 {
    margin: 0;
  }
}