@import "../../theme/defs";

.message-bar {
  border-radius: 8px;

  &.message {
    &--success {
      .message-bar {
        &__container {
          border-color: $ft-teal-4;
        }

        &__icon {
          color: $ft-teal-4;
        }
      }
    }

    &--warning {
      .message-bar {
        &__container {
          border-color: $ft-orange-3;
        }

        &__icon {
          color: $ft-orange-3;
        }
      }
    }

    &--fail {
      .message-bar {
        &__container {
          border-color: $ft-berry-4;
        }

        &__icon {
          color: $ft-berry-4;
        }
      }
    }
  }

  &__container {
    display: flex;
    border: solid 1px;
    padding: $space-sm $space-md;
    border-radius: 8px;
    border-color: $ft-brand-primary-blue;
    background: $ft-white;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  }

  &__icon {
    font-size: $space-lg;
    color: $ft-brand-primary-blue;

    &-container {
      padding-right: $space-sm;
    }
  }
  &__display-hidden {
    display: none;
  }

  &__msg {
    &-container {
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  &__close-button {
    color: $ft-grey-3;
    cursor: pointer;
    line-height: 1;
  }
}
