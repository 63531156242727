@import "src/theme";

.questionaire {
  display: flex;
  flex: 50%;
  gap: $space-md;
}

.ft-card {
  &__ui-style-standard {
    border-radius: 0;
    box-shadow: 0 0 14px #0000001a;
    flex: 1;
  }

  &__body {
    display: flex;
    padding: $space-md;
    color: $ft-dark-blue;
    gap: $space-sm;
    margin-bottom: $space-lg;

    .MuiFormGroup-root {
      display: contents;
    }

    .ft-icon {
      font-size: 42px;
    }

    & .tooltip-container {
      position: relative;

      & .ftpopover {
        position: absolute;
        right: -$space-sm;
        top: -$space-md;
      }
    }

    & .input-toggle {
      color: $ft-text-color;
    }
  }
}
