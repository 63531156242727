$icomoon-font-family: 'ft-icons' !default;
$icomoon-font-path: '.' !default;

$ft-drawer-benefits-income: '\e900';
$proposals-1: '\e901';
$ft-add: '\e902';
$ft-alert: '\e903';
$ft-arrow-down: '\e904';
$ft-arrow-up: '\e905';
$ft-attachement: '\e906';
$ft-audio: '\e907';
$ft-beyond-bulls-bears: '\e908';
$ft-bookmark-selected: '\e909';
$ft-boomark-default: '\e90a';
$ft-calculator: '\e90b';
$ft-calendar: '\e90c';
$ft-carrot-down: '\e90d';
$ft-carrotleft: '\e90e';
$ft-carrot-right: '\e90f';
$ft-carrot-up: '\e910';
$ft-cart: '\e911';
$ft-chart: '\e912';
$ft-chat: '\e913';
$ft-checkmark: '\e914';
$ft-circle-down-carrot: '\e915';
$ft-clock: '\e916';
$ft-confirm: '\e917';
$ft-cross: '\e918';
$ft-document: '\e919';
$ft-download: '\e91a';
$ft-edit: '\e91b';
$ft-error: '\e91c';
$ft-eye-hide: '\e91d';
$ft-eye-reveal: '\e91e';
$ft-feature-ribbon: '\e91f';
$ft-flag: '\e920';
$ft-folder-inactive: '\e924';
$ft-gift: '\e925';
$ft-grid: '\e926';
$ft-home: '\e927';
$ft-info: '\e928';
$ft-list: '\e929';
$ft-lock: '\e92a';
$ft-logout: '\e92b';
$ft-mail: '\e92c';
$ft-meatballs: '\e92d';
$ft-menu: '\e92e';
$ft-message: '\e92f';
$ft-new-window: '\e930';
$ft-open-new-tab: '\e931';
$ft-output: '\e932';
$ft-pause: '\e933';
$ft-payment: '\e934';
$ft-phone: '\e935';
$ft-play: '\e936';
$ft-play-box: '\e937';
$ft-podcast: '\e938';
$ft-print: '\e939';
$ft-profile: '\e93a';
$ft-question: '\e93b';
$ft-reset: '\e93c';
$ft-save: '\e93d';
$ft-search: '\e93e';
$ft-shield: '\e93f';
$ft-star-active: '\e940';
$ft-star-inactive: '\e941';
$ft-subtract: '\e942';
$ft-swipe: '\e943';
$ft-thinks: '\e944';
$ft-triangle-down: '\e945';
$ft-triangle-up: '\e946';
$ft-upload: '\e947';
$ft-cog: '\e948';
$ft-drawer-annuity: '\e949';
$ft-hand-money: '\e94a';
$ft-drawer-expenditure: '\e94b';
$ft-drawer-risk: '\e94c';
$ft-drawer-total-wealth: '\e94d';
$ft-dc-pension: '\e94e';
$ft-state-pension: '\e94f';
$ft-pound-arrows: '\e950';
$ft-value: '\e951';
$ft-checkmark2: '\e952';
$ft-copy: '\e953';
$ft-amount: '\e954';
$ft-annuity: '\e955';
$ft-zoom: '\e956';
$ft-download-2: '\e957';
$ft-remove: '\e958';
$ft-update-profile: '\e95f';
$ft-finger: '\e960';
$ft-arrow: '\e961';
$ft-average-health: '\e962';
$ft-healthy: '\e963';
$ft-income: '\e964';
$ft-new-client: '\e965';
$ft-outcome: '\e966';
$ft-smoker: '\e967';
$ft-basket: '\e968';
$ft-warning: '\e969';
$ft-x-switch: '\e96a';
$ft-save2: '\e96b';
$ft-stagg24x24: '\e96c';
$ft-checkmark3: '\e97c';
$secure: '\e97d';
$flexible: '\e97e';
$dynamic: '\e97f';
$launchpad-step: '\e980';
$email: '\e981';
$error: '\e982';
$flexible1: '\e983';
$growth: '\e984';
$income-calculations-2: '\e985';
$linked-in: '\e986';
$logout: '\e987';
$profile: '\e988';
$proposal-complete: '\e989';
$proposal-in-progress: '\e98a';
$view: '\e98b';
$warning: '\e98c';
