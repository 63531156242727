@import "src/theme/";

// Link
.link {
  color: $ft-primary;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #33adff;
    text-decoration: underline;
  }
}

// Input

.MuiInputLabel-shrink {
  transform: translate(0, 8px) scale(0.75) !important;
}

.MuiTextField-root .MuiInputLabel-root {
  top: -8px;
}

.MuiFormHelperText-root {
  color: $ft-red-1 !important;
  font-size: calc($space-md/2) !important;
  font-family: inherit !important;
  display: flex;
  line-height: 100%;

  &::before {
    content: "\e926";
    font: 14px/1 ft-icons;
    color: $ft-red-1;
    padding-right: 0.5rem;
  }
}

.input-field {
  border: $qf-input-border;
  margin-bottom: 1rem;
  padding: 0.3rem 1rem;

  div {
    width: 98%;
  }
}

.input-field-xl {
  box-shadow: none;
  transition: background-color 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms,
    box-shadow 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding-top: 0.6rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #e8e8e8;

  &.has-error {
    border-bottom: 1px solid $ft-red-1;
  }

  input {
    font-family: "TT Commons Pro", sans-serif;
    font-size: $h3-size;
    font-weight: 200;

    &::placeholder {
      color: $ft-primary-focus;
      opacity: 1;
    }
  }
}

// validation outline
.hasErrorOutline {
  border-color: $ft-red-1;
}

.MuiInputLabel-root.Mui-error {
  color: unset !important;
}

// Select Box

li.MuiMenuItem-root,
.MuiSelect-select,
.MuiFormLabel-root {
  font-family: "TT Commons Pro", sans-serif !important;
}

.MuiSelect-select {
  :hover {
    color: $ft-sky-blue;
  }
}

.selectLabel {
  :hover {
    color: $ft-text-color;
  }
}

.select-field {
  border: $qf-input-border;
  margin-bottom: 1rem;
  padding-top: 8px;
  background-color: $ft-white;

  &:hover {
    border: 1px solid $ft-sky-blue;
  }

  .MuiInputLabel-shrink {
    transform: translate(16px, -4px) scale(0.75) !important;
  }

  .MuiFormControl-root {
    .MuiInputLabel-root {
      font-weight: 400;
      color: $ft-text-color;
    }

    .MuiOutlinedInput-root,
    .MuiInputLabel-animated {
      border-radius: 0;

      :hover {
        border: none !important;
        color: $ft-sky-blue;
      }
    }

    // .MuiSelect-select {
    //   // background-color: red;
    //   color: orange;
    //   :hover {
    //     color: $ft-sky-blue;
    //   }
    // }

    .MuiInputLabel-root {
      :hover {
        color: $ft-sky-blue;
      }
    }

    fieldset {
      border: none; // removes native outline
    }
  }
}

// FTButton
.button-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;

  .bold-button {
    padding: .7rem 1.5rem;
  }

  .medium-bold-button {
    padding: 12px 1rem;
  }

  & button {
    font-size: $space-sm;
    border-style: solid;
    border-radius: 0;
    text-transform: unset;
    font-family: "TT Commons Pro", sans-serif;
    font-weight: 600;

    &.MuiButton-contained {
      background-color: $ft-primary;
      border: 1px solid $ft-primary;
      color: $ft-white;

      &:hover {
        background-color: $ft-primary-focus;
      }
    }

    &.MuiButton-outlined {
      border: 1px solid $ft-primary;
      color: $ft-primary;
      background-color: $ft-white;


      &:hover {
        border: 1px solid $ft-primary;
        color: $ft-primary-focus;
        background-color: $ft-secondary-button-hover;

      }
    }

    &.MuiButton-text {
      color: $ft-primary;
      font-weight: 700;
      font-size: $smallest-size;
      background-color: $ft-white;

      &:hover {
        color: $ft-primary;
        font-weight: 700;
        font-size: $smallest-size;
        background-color: $ft-white;
      }

      .text-variant-button-icon {
        font-size: $smallest-size !important;
      }
    }

    &:disabled {
      background-color: $ft-grey-2;
      color: $ft-black;
      border: 1px solid $ft-grey-2;
      opacity: 1;
      font-weight: 600;
    }
  }
}

// btn-diabled
// &__is-disabled {
//   opacity: .4;
//   cursor: default;
// }

.parent-width-btn {
  display: contents;

  & button {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1.2rem 14px;
    font-size: 0.875rem;
    border-style: solid;
    border-radius: 0;
    text-transform: unset;
    font-family: "TT Commons Pro", sans-serif;
    font-weight: 600;

    &.MuiButton-contained {
      background-color: $ft-primary;
      border-color: $ft-primary;
      color: $ft-white;

      &:hover {
        background-color: $ft-primary-focus;
      }
    }

    &.MuiButton-outlined {
      border-color: $ft-primary;
      color: $ft-primary;

      background-color: $ft-white;

      &:hover {
        border-color: $ft-primary-focus;

        color: $ft-primary-focus;
        background-color: $ft-secondary-button-hover;
      }
    }

    &:hover {
      box-shadow: 0 4px 10px #21212126;
    }

    &:disabled {
      background-color: $ft-grey-2;
      color: $ft-black;
      border: 1px solid $ft-grey-2;
      opacity: 1;
      font-weight: 600;
    }
  }
}

.disabled-cursor {
  cursor: auto !important;

  div {
    cursor: not-allowed !important;
  }
}

.disabled-cursor-input {
  cursor: not-allowed;
  background-color: $ft-grey-1;
  border: 1px solid $ft-grey-2;
  opacity: 1;
}

.left-aligned-btn {
  justify-content: flex-start;
}

// FTBox
.ftbox-container {
  background-color: $ft-white;
  max-width: $ft-container-width;
  border-radius: 20px 20px 0 0;
  box-shadow: $card-box-shadow;
  margin-bottom: $space-lg;

  &.half-width {
    width: 50%;
    border-radius: 10px;
  }
}

.ftbox-header {
  padding: $space-sm $space-md;
  background: $ft-primary;
  border-radius: 20px 20px 0 0;
  color: $ft-white;
  display: flex;
  justify-content: space-between;
  font-size: $h4-size;

  &.half-width {
    padding: $space-sm $space-lg;
    border-radius: 10px 10px 0 0;
  }

  & .ftpopover {
    display: flex;
    align-items: center;
    margin-left: $space-xs;
  }
}

.display-noheader {
  display: none;
}

.ftbox-body {
  padding: 1rem 2rem;

  a {
    &:hover {
      color: #33adff;
      text-decoration: underline;
    }
  }
}

.simple-withcontrols {
  display: flex;
  width: 100%;
  padding: 0 0.5rem;
  justify-content: space-between;
  h5 {
    color: $ft-white;
  }
}

// FT Icon

.ft-icon {
  display: flex;

  &__icon {
    height: 40px;
    width: 40px;
    margin-right: 0.75rem;
  }
}

.ft-icon-position {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  padding: 1rem;
  font-size: 1.3125rem;
}

.ft-icon-position.sm {
  width: 40px;
  height: 40px;
  padding: 7px;
}

// FTMessageBar

.borderless {
  display: block;
  float: right;
  margin-bottom: $space-sm;

  &.warning {
    color: $ft-orange-3;
  }

  &.info {
    color: $ft-orange-2;
  }

  & .message-bar__container {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  &.half-width {
    width: 47%;
  }
}

.ft-message-lite {
  display: flex;

  i {
    padding: 1.2rem 0.3rem 0 0;
  }

  &.info {
    color: $ft-orange-2;
  }

  &.warning {
    color: $ft-orange-3;
  }
}

// FTErrorModal

.error-modal-body {
  margin: 0;
  text-align: left;
  width: 700px;
  height: initial;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  outline: 0;
  box-shadow: 0 5px 15px #00000080;
  padding: 3.5rem;
  z-index: 1300;

  i {
    font-size: 3.5rem;
    color: $ft-red-1;
  }

  z-index: 1500;
}

.error-modal-title {
  color: $ft-red-1;
  margin-bottom: 1rem;
}

.error-modal-desc {
  overflow: auto;
  max-height: 50vh;
  margin-bottom: 1rem;
}

.error-dismiss {
  padding-top: 2.5rem;

  span {
    padding-right: 1rem;
    cursor: pointer;
  }
}

// FT logout modal
.logout-modal__header__icon>i {
  color: $ft-primary;
  margin-bottom: .5rem;
}

.logout-modal__header__title {
  color: $ft-primary;
  margin-top: 1rem;
  margin-bottom: .5rem;
}

.buttons-area {
  padding-top: 1.5rem;
  display: flex;
  gap: 1rem;
}

.MuiModal-root {
  overflow-y: scroll;

  .close-button {

    button {
      font-size: $p-size !important;
    }

    .ft-icon {
      margin-right: 5px !important;
    }
  }
}

// FT Support Modal
.support-modal-body {
  margin: 0 0 20px 0;
  text-align: left;
  width: 65%;
  height: initial;
  top: 10% !important;
  left: 50%;
  transform: translate(-50%) !important;
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  outline: 0;
  box-shadow: 0 5px 15px #00000080;
  text-align: left;
  padding: 30px 40px 30px 100px;
  z-index: 1300;

  i {
    font-size: 3.5rem;
    color: $ft-sky-blue-4;
    font-weight: normal;
  }

  .button-container {
    padding: 0;
  }

  .button-container button.MuiButton-text {
    font-weight: normal;
    color: $ft-sky-blue-4;
    padding: 0;

    span i {
      font-size: 14px;
      color: $ft-sky-blue-4;
    }
  }

  h5 {
    font-weight: 700;
  }
}

// FAQ
.faq-popup {
  margin: 0 0 20px 0;
  text-align: left;
  width: 65%;
  height: initial;
  top: 10% !important;
  left: 50%;
  transform: translate(-50%) !important;
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  outline: 0;
  box-shadow: 0 5px 15px #00000080;
  text-align: left;
  padding: 30px 75px;
  z-index: 1300;

  i {
    font-size: 2rem;
    color: $ft-sky-blue-4;
    font-weight: normal;
  }

  h3 {
    color: $ft-dark-blue;
    margin: 0 0 .8em;
  }

  .search-container {
    margin-bottom: $space-lg;
    padding: 40px $space-xl $space-xl $space-xl;
    background-color: $ft-dark-blue;
    border-radius: 20px;

    .qf-input-text {
      max-width: -webkit-fill-available;
      position: relative;
      align-items: center;
      font-size: 1rem;
      height: 50px;
      padding-bottom: 0;
      border: none !important;
      padding: 0 $space-sm 0 $space-xl;
      background: #fff;
      border-radius: 0;
      height: auto;
      line-height: 1.2em;
      border: 1px solid #c9c9c9;
      width: 100%;
      box-shadow: none;
      transition: background-color .2s cubic-bezier(0, 0, .2, 1) 0ms, box-shadow .2s cubic-bezier(0, 0, .2, 1) 0ms;

      .qf-input-text__prefix {
        position: absolute;
        left: 0;
        top: $space-sm;
        padding: 0 $space-sm;
      }

      .qf-input-text__suffix {
        position: absolute;
        right: 0;
        top: $space-sm;
        padding: 0 $space-sm;

        i {
          font-size: $space-md;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }

    .input-field-xl input {
      font-weight: 700;
      color: $ft-sky-blue;
    }

    h2 {
      color: $ft-white;
      margin: 0 0 .8em;
    }

    input::placeholder {
      color: $ft-grey-4;
      font-weight: 400;
      font-size: $h6-size;
    }

  }

  .split {
    display: flex;

    .themes {
      flex: 1;
      cursor: pointer;
      font-size: $p-large-size;

      >div {
        padding: $space-sm $space-md;
        border: 1px solid $ft-grey-1;
        border-right: none;
        border-top: none;
        font-weight: bold;
        color: $ft-black;

        &:first-child {
          border-top: 1px solid $ft-grey-1;
        }
      }

      >.selected-theme {
        border-left: 4px solid $ft-brand-primary-blue;
        padding-left: 20px;
      }
    }

    .results-container {
      flex: 3;

      .qa-section {
        border: 1px solid $ft-grey-1;
        padding: $space-lg $space-xl;
        padding-top: 12px;

        >div {
          border-bottom: 1px solid $ft-grey-1;

          &.border-highlight {
            border-bottom: 2px solid $ft-brand-primary-blue;
          }

          .question {
            display: flex;
            font-size: 19.2px;
            color: $ft-brand-primary-blue;
            cursor: pointer;
            min-height: 85px;
            flex-direction: column;
            justify-content: center;

            .fa {
              font-size: 23px;
              margin-right: 2px;
              position: relative;
              top: 3px;
            }
          }

          .answer {
            padding: $space-md $space-lg;
            margin: 0;
            padding-top: 0;
          }
        }
      }
    }

    .no-results-found-icon {
      flex: 1;
      width: 100%;
      margin-top: 5px;
      height: auto;
      // object-fit: cover;
      padding: 10px;
      background: no-repeat;
    }

    .no-results-found-text {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $ft-dark-blue;
    }
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    display: none
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    .page-num {
      margin: 20px 6px ;
      padding: 6px;
      cursor: pointer;
      
      &.selected-page {
        padding: 6px;
        color: $ft-brand-primary-blue;
        font-weight: bold;
        border-bottom: 3px solid $ft-brand-primary-blue;
      }
    }
  }
}

.resources-modal {
  &-container {
    margin-top: calc(-1 * #{$space-md});
    text-align: left;
    padding-left: 80px;
    padding-right: 80px;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $space-sm;

    &__btn {
      cursor: pointer;

      span {
        margin-right: $space-xs;
      }
    }
  }

  &__title {
    color: $qf-color-primary-background;
    margin: 0 0 .8em;
  }

  &__item {
    border-radius: $space-xs;
    border: 1px solid $ft-grey-1;
    padding: $space-md;
    display: flex;
    justify-content: flex-start;
    flex-flow: row;
    margin-bottom: $space-sm;

    &__icon {
      color: $ft-sky-blue;
      margin-right: $space-sm;
    }

    .ft {
      color: $ft-sky-blue;
      font-size: 3.5rem;
    }

    &__content {
      &__desc {
        margin-bottom: $space-sm;
      }

      &__title {
        font-weight: 700;
        margin: 0 0 .8em;
      }

      &__link {
        cursor: pointer;
        width: fit-content;

        &__container {
          display: flex;
          align-items: center;

          span {
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
        }

        .ft {
          margin-left: $space-sm;
          font-size: $space-sm;
        }
      }
    }
  }
}

// FTKnobButton

.knob-button-container {
  padding: 1px;
  line-height: 6px;
  color: $ft-primary-focus !important;
  border: 1px $ft-primary-focus solid;
  border-radius: 20px;
  height: 15px;
  width: 15px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

// Support Modal Contents
.contents {
  hr {
    border-top: 1px solid $qf-color-neutral;
  }

  h2 {
    color: $ft-sky-blue-4;
    margin: 0 0 .8em;
  }

  h3 {
    color: $ft-sky-blue-4;
  }

  .info {

    &-phone,
    &-email {
      font-weight: bold;
    }

    &-email {
      color: $ft-sky-blue-4;
    }

    &-phone {
      margin-bottom: 0;
    }
  }

  .contents-details {
    display: flex;
    gap: 60px;

    .split {
      display: flex;
      gap: 60px;
      flex-direction: column;
      margin-top: 30px;

      >.contact-details {
        span {
          font-size: 13px;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-left: 50px;
      }

      >div {
        flex: 1;
        display: flex;

        >.advisor-icon {
          flex: 1;
          margin-right: $space-lg;

          img {
            border-radius: 50%;
            width: 100%;
          }
        }

        >.advisor-details,
        .contact-details {
          flex: 3;
          font-size: $smallest-size;

          span {
            margin: auto 0;
            margin-right: $space-xs;
          }

          >.title {
            margin: 4px 0;
            font-size: $p-size;
          }

          h6 {
            margin-bottom: 0;
            font-weight: 700;
          }

          a {
            margin: 0 4px;
          }
        }
      }
    }
  }

  .icon {
    font-size: 2rem;
    border-radius: 6px;

    &--email {
      color: $ft-sky-blue-4;
    }

    &--linked-in {
      color: #0077b7;
    }
  }
}