@import "src/theme/";
@import 'src/shared.scss';

.view-proposal-container {
  margin: $space-sm $space-sm;
  max-width: 1500px; // $ft-container-width
  min-height: calc(100vh - 72px);
  display: flex;
  align-items: stretch;
  height: 100%;

  .ftbox-header {
    font-size: $space-sm;
  }

  >.ft-split__first {
    max-width: 408px;
    flex: 1;
    padding-right: 1.5rem;
  }

  >.ft-split__middle {
    width: clamp(894px, 10vw, 1112px);
    position: relative;
    flex: 3.1;
    padding: 0.6rem 1rem 0 0;
  }

  .flex-split {
    display: flex;

    >* {
      padding: 0 17px;

      h6 {
        font-weight: bold;
        font-size: 16px;
      }
    }

    >.first-col {
      flex: 6;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      span {
        padding: 0.5rem 0;
      }

      a {
        padding: 1rem 0;
        text-decoration: underline;
        color: $ft-primary-focus;
      }

      p {
        font-size: $smallest-size;
      }

      .client-results__explore-ways-url {
        margin-bottom: $space-md;

        a {
          text-decoration: underline;
        }
      }

      .ft-button {
        width: fit-content;
      }
    }

    >.second-col {
      flex: 4;
      border-left: 1px $ft-grey-1 solid;
      border-right: 1px $ft-grey-1 solid;
      display: flex;
      flex-direction: column;

      span {
        padding: 0.5rem 0;
      }

      .legend {
        &-container {
          display: flex;
          justify-content: space-between;

          &__right-side {
            padding-right: $space-xs;
          }
        }

        &__item {
          display: flex;
          justify-content: flex-start;

          &__pointer {
            height: 12px;
            width: 12px;
            background: var(--series-color);
            border-radius: 50%;
            margin: auto $space-xs;
          }

          &__name {
            font-size: 8px;
            font-weight: 700;
          }
        }
      }
    }

    >.third-col {
      flex: 3.5;
      display: flex;
      flex-direction: column;

      span {
        padding: 0.5rem 0;
      }

      p {
        font-size: $smallest-size;
      }

      .small-text {
        line-height: 1.5em;
      }
    }
  }
}

// Disclaimer Content
.drawer-content__disclaimers {
  color: $ft-black;
  font-size: $smallest-size;
  margin-top: $space-sm;

  p {
    margin: 0 0 0.8em;
    line-height: 1.5em;
  }
}

// Risk Drawer

.highcharts-container {
  position: relative;
  flex-basis: 0;
  flex-grow: 2;
  display: block;
  overflow: hidden;
}

.risk-provider {
  &__label {
    color: gray;
  }

  &__content {
    padding: 1rem;
    background: $qf-input-group-background;
    border-radius: 10px;
  }

  &__container {
    margin-top: $space-sm;
    display: flex;
  }
}

// Expenditure Drawer
.breakdown-block.regualr-expenses-block {
  h6 {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0 0 .8em;
  }
}

// Cash Reserves
.fees-charges {
  display: flex;
  gap: 8px;
  margin-bottom: $space-sm;

  >.fees-charges-percentage {
    >.ft-input-number {
      flex: 3;
    }

    >.fees-charges-percentage-symbol {
      position: absolute;
      margin-left: 70px;
      margin-top: -49px;
    }
  }

  >.icon-container {
    flex: 1;
    text-align: center;
    color: $ft-grey-1;

    >.ft-icon {
      display: inline-block;
      transform: rotate(90deg);
      font-size: 32px;
      line-height: 64px;
    }
  }

  >.total-wealth {
    flex: 2;
    display: flex;
    padding-left: 6px;
    flex-direction: column;
    justify-content: flex-start;

    >div:nth-child(1) {
      font-weight: bold;
      color: $ft-grey-3;
    }
  }
}

.header-right-controls-set {
  display: flex;
  align-items: center;
  padding: 0 .5rem;
  gap: 1.5rem;

  i {
    padding-right: 8px;
  }

  div {
    cursor: pointer;
  }
}

// Common
.input-flex-container {
  border-radius: 10px;

  .name {
    color: $qf-input-label-color;
  }

  .delete-span {
    color: rgb(194, 1, 1);
    cursor: pointer;
  }

  .readonly {
    pointer-events: none;
    color: gray;
  }

  .type {
    margin-top: 12px;
  }

  .input-field {
    margin-bottom: 0;
  }
}

.drawer-input-group {
  &--sub-text {
    color: $qf-input-label-color;
    margin-top: $space-xs;
  }
}

.delete-group {
  align-items: center;

  >*:not(:nth-last-child(1)) {
    margin-bottom: $space-xs;
  }

  .input-flex-container {
    display: flex;
    justify-content: space-between;
  }

  qf-button {
    flex-basis: 0;
    margin: auto !important;
    flex-grow: 3;
    text-align: center;
  }
}

// Wealth 
.total-wealth {
  h6 {
    padding-bottom: $space-sm;
    margin-bottom: $space-sm;
    border-bottom: 1px solid rgb(22, 55, 88, .15);
  }

  qf-label-value::ng-deep {
    .qf-label-value__label {
      color: $ft-grey-4;
    }
  }
}

.wealth-breakdown {
  padding: $space-sm 0;
}

.sipp {
  &__input-group {
    margin: 1rem 0;
    background: none;
    padding: 0;

    .button-container {
      visibility: hidden;
    }

    .name {
      display: none;
    }
  }
}

// Income
.ft-drawer-input-group {
  padding: $space-sm;
  background: $qf-input-group-background;
  border-radius: 10px;
  display: block;
  margin: 20px 0;
}

.as-of {
  margin-top: -16px;
  font-size: small;
}

.qf-input-number,
.qf-input-dropdown,
.qf-input-text {
  @include drawer-input-width;
}

// Add new modal
.add-new-item-modal {
  text-align: left;
  width: 335px;
  height: initial;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  outline: 0;
  box-shadow: 0 5px 15px #00000080;
  padding: $space-xl;
  z-index: 1300;

  .ft-switch {
    display: flex;
    align-items: flex-end;

    .toggle {
      margin-left: 0;
      margin-right: $space-sm
    }
  }
}

.include-annuities-switch {
  div {
    margin-top: 1rem;
    margin-left: 0.8rem;
    margin-right: 1rem;

    .toggle-label {
      margin-left: 1rem;
    }
  }
}

// Tab Section
.chart-content {
  &-container {
    display: flex;
    justify-content: space-between;
    gap: $space-xl;
  }

  &__content,
  &__description {
    flex-basis: 50%;

    .disclaimer {
      margin: 0;
    }
  }

  &__description {
    font-size: $smallest-size;
  }
}

.qf-label-value {
  display: flex;

  >div {
    margin-bottom: $space-xs;
  }

  &__label {
    color: #212121;
    flex: 0 1 auto;
  }

  &__value {
    flex: 1 0 auto;
    text-align: right;
    font-weight: 700;
  }

}

.line-chart {
  flex: 80%;
  pointer-events: none;

  &-description {
    flex: 20%;
    padding-left: $space-md;
  }

  &-container {
    margin-bottom: $space-md;
    display: flex;
  }
}

.consumption {
  &__chart-header {
    display: flex;
    justify-content: flex-end;

    &__title {
      display: inline-flex;
      justify-items: center;

      qf-popover {
        margin-left: $space-sm;
      }
    }
  }
}

.portfolio-allocation-body-container {
  display: flex;
  justify-content: space-between;

  .portfolio-allocation-description {
    flex-basis: 0;
    flex-grow: .55;
    color: #000;

    p {
      margin: 0;
      margin-top: 12px;
    }
  }

  .portfolio-allocation {
    &-name h5 {
      font-weight: normal;
    }

    &-header {
      h5 {
        font-weight: 700;
      }
    }

    &__investment-info {
      max-width: 75%;
      margin-bottom: $space-md;
    }

    &-detail {
      justify-items: center;
      display: inline-flex;
      flex-direction: column;
      flex-basis: 0;
      flex-grow: .45;

      .risk-radial {
        &-container {
          display: flex;
          justify-content: space-between;

          p {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }
        }

        &__risk-name {
          text-align: center;
          margin-top: $space-sm;
          color: $ft-teal-4;
        }
      }
    }

    &-link-container {
      display: flex;
      padding: $space-md 0;
      border-bottom: 1px solid $qf-color-neutral;
      border-top: 1px solid $qf-color-neutral;

      a {
        margin: auto 0;
      }

      .pdf-icon {
        position: relative;
        width: 36px;
        height: 36px;
        float: left;
        margin-right: 20px;
      }
    }
  }

  a {
    font-size: $p-large-size;
    color: $ft-brand-primary-blue;
    text-decoration: underline;
  }

  qf-icon {
    font-size: $h4-size;
    top: 6px;
    position: relative;
    margin-right: $space-sm;
  }
}

.projected-allocation-toggle {
  margin: $space-md 0;
  display: flex;

  .bold-button {
    background: #fafaf9;
  }

  .button-container .bold-button {
    padding: 9px 14px;
  }

  .button-container button.MuiButton-outlined {
    color: $ft-grey-3;
  }
}

.disclaimer {
  font-size: $smallest-size;
  color: $ft-disclaimer-text-color;
  line-height: 1.5em;
}

.bottom-disclaimer {
  font-size: $smallest-size;
  color: $ft-disclaimer-text-color;
  margin: $space-md 0;
}

// HighChart Styles
.ft-amount-chart {
  .legend-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 20px;
    font-size: $p-size;
    margin-left: 8rem;

    >div {
      flex-basis: 250px;

      > :not(.main-scenario) {
        font-size: $smallest-size;
      }

      >.market-conditions {
        margin: 0;
        margin-top: $space-xs;
      }
    }
  }

  // highcharts-chart::ng-deep {
  //     background: linear-gradient(#f4f4f46e 0%, #fff 11%);
  //     border-radius: 12px;

  //     .chart-label-title {
  //         font-size: $smallest-size;
  //         color: $ft-black;

  //         &.proposal-pdf {
  //             font-weight: 700;
  //         }
  //     }
  // }
}

.stacked-bar {
  &__tooltip-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-content {
      padding: $space-md;

      &__title {
        font-weight: 700;
        padding-bottom: $space-sm;
      }

      &__list-item {
        font-weight: 700;
        font-size: $smallest-size;
        padding-bottom: $space-sm;
      }
    }
  }
}

.ft-legend-container {
  display: flex;
  gap: $space-xxl;
}

// Talking Points
.talking-point {
  &-container {
    position: absolute;
    top: 620px;
    right: 0;
    border: 1px solid $ft-grey-3;
    box-shadow: 0 0 5px rgba(0, 0, 0, .15);
    border-radius: 10px 0 0 10px;
    padding: $space-sm;
    background: $qf-color-content-background;
    z-index: 2;

    &.show-content {
      width: clamp(251px, 15vw, 15%);
    }
  }

  &__content {
    display: none;
    padding: $space-md;

    &.show {
      display: block;
    }

    &__title {
      font-weight: bold;
    }

    &__description {
      font-size: $smallest-size;
    }
  }

  &__toggle {
    .ft-switch {
      display: flex;
      flex-direction: row-reverse;
      margin: 0;

      &-label {
        margin-right: $space-sm;
      }
    }

    &-container {
      display: flex;
      margin-right: 0;
    }
  }
}

.drawer-content__title-container {
  h5 {
    color: $ft-white;
  }
}