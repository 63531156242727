// Franklin Templeton Style Guide values.
// For reference https://www.figma.com/file/LduVWOFAOBLrJZgZ0EnJwR/High-Level-RIO-Proposal-Screen?node-id=261%3A19828

$default-font-size: 16px;

// SPACING
$space-xs: .5rem;   //   8px
$space-sm: 1rem;    //  16px
$space-md: 1.5rem;  //  24px
$space-lg: 2rem;    //  32px
$space-xl: 3.5rem;  //  56px
$space-xxl: 4.5rem; //  72px
$space-xxxl: 9rem;  // 144px

// Blue
$ft-sky-blue-1: #ddeaff;
$ft-sky-blue-2: #91b9ff;
$ft-sky-blue-3: #5291ff;
$ft-sky-blue: #3769ff;
$ft-sky-blue-4: #1446e1;

// Green
$ft-green-2: #81bb00;

// Teal
$ft-teal: #00bfb3;
$ft-teal-1: #d3fbf7;
$ft-teal-2: #72dbd5;
$ft-teal-3: #00a096;
$ft-teal-4: #00847d;

// Black / White
$ft-white: #fff;
$ft-light-grey: #f4f4f4;
$ft-grey-1: #e6e6e6;
$ft-grey-2: #bfbfbf;
$ft-grey-3: #8c8c8c;
$ft-grey-4: #595959;
$ft-black: #212121;

// Purple
$ft-purple1: #e7dffe;
$ft-purple2: #b39af5;
$ft-purple3: #8760ef;
$ft-purple: #6730e3;
$ft-purple4: #4b00b6;

// Orange
$ft-orange-1: #ffe0d2;
$ft-orange-2: #ff9665;
$ft-orange-3: #ff6035;
$ft-orange: #e24100;
$ft-orange-4: #be3200;

// Fuchsia
$ft-fuchsia-1: #fae4fd;
$ft-fuchsia-2: #f0b4f9;
$ft-fuchsia-3: #cb6eee;
$ft-fuchsia: #c042ea;
$ft-fuchsia-4: #a525d0;

// Berry
$ft-berry-1: #ffdde6;
$ft-berry-2: #f589a2;
$ft-berry-3: #f04f74;
$ft-berry: #ff0f52;
$ft-berry-4: #dc0546;

// Button states
$ft-primary-button-hover: #345edd;
$ft-secondary-button-hover: #ededed;

// TYPOGRAPHY
$standard-line-height: 150%;
$smaller-line-height: 140%;
$h1-size: 2.375rem;     //  38px
$h2-size: 1.75rem;      //  28px
$h3-size: 1.563rem;    //  25px
$h4-size: 1.3125rem;    //  21px
$h5-size: 1.3125rem;    //  21px
$h6-size: 1.2rem;       //  19.2px
$p-large-size: 1.2rem;  //  19.2px // not in the design language, delete?
$p-size: 1rem;          //  16px
$smallest-size: .813rem;//  13px
