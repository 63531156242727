@import "src/theme/";


$drawer-position: 174px;
$drawer-width: 502px;

.drawer-container {
    background-color: $ft-white;
    height: 100%;
    width: clamp(175px, 14vw, 219px);
    .drawer__edit-icon {
        margin-right: 5px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1600px) {
        margin-left: calc(100px + (100vw - 1200px) * (115 - 189) / (1200 - 1600));
    }

    @media (max-width: 1199px) {
        margin-left: 115px;
    }

    @media (min-width: 1601px) {
        margin-left: 175px;
    }

    // hr {
    //     opacity: .15;
    //     border-top: 1px solid gray;
    //     margin-left: 106px;
    //     margin-top: $space-sm;
    //     margin-bottom: $space-sm;
    // }

    .period-buttons {
        display: flex;
        border-bottom: 1px solid rgba(22, 55, 88, .15);
        padding-bottom: $space-sm;
        margin-left: -4px;

        .ft-button {
            button {
                padding: $space-xs 2.7rem;
                font-size: $p-size;
            }
        }

        .ft-popover {
            margin: auto;
        }
    }

    // Dropdown style
    .select-field {
        padding: 8px 14px 4px;
    }

}

.drawer {
    &:nth-child(1) {
        border-top: 1px solid rgba(22, 55, 88, .15);
    }

    &__btn-container {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: $space-sm .4rem $space-sm 0;
        position: relative;
        z-index: 1;
        border-right: 1px solid transparent;
        border-bottom: 1px solid rgba(22, 55, 88, .15);
        min-width: 173px;

        &.inactive {
            cursor: not-allowed;
        }

        &.selected {
            border-right: 1px solid $qf-color-content-background;
            background: $qf-color-content-background;
            z-index: 3;

            .drawer {

                &__icon {
                    color: $qf-color-content-background;
                    background-color: $ft-teal-4;
                    box-shadow: inset 0 0 0 4px $qf-color-content-background;
                    border-width: 1px;
                }

                &__edit-icon {
                    display: none;
                }
            }
        }
    }

    &__edit-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: $ft-teal-4;
    }

    &__icon {
        color: #c6c6c6;
        z-index: 10;
        background-color: $qf-color-content-background;
        border: 2px solid #c6c6c6;
        transition: 500ms ease all;
        width: 2.5rem;
        height: 2.5rem;
        margin: auto;

        &:hover,
        &:focus {
            border-color: $ft-sky-blue-3;
            color: $ft-sky-blue-3;
        }

        &:focus {
            box-shadow: 0 0 0 2px $ft-sky-blue-2;
        }

        &:active {
            color: $qf-color-content-background;
            background-color: $ft-teal-4;
            box-shadow: inset 0 0 0 4px $qf-color-content-background;
            border-width: 1px;
        }
    }

    &__title {
        font-size: $p-size;
        color: $ft-black;
        font-weight: normal;

        &-container {
            flex-grow: 1;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            margin-left: $space-xs;
        }
    }

    &__sub-title {
        color: $ft-teal-4;
        font-size: $p-size;
        font-weight: bold;
    }
}

.header-shadow {
    border-image: linear-gradient(to bottom, #dadada6b, #{$ft-white}) 204% 0%;
    border-top-width: 20px;
    border-style: solid;
    border-left: none;
    border-right: none;
    clip-path: inset(0 0 3px 0);
    padding-left: 113px;
}

.header {
    padding: $space-sm $space-md;

    span {
        font-size: $smallest-size;
    }
}

.expanded-drawer {
    position: absolute;
    background: $qf-color-content-background;
    padding: $space-md;
    width: $drawer-width;
    left: 25.4rem;
    @media (max-width: 1420px) {
        left: 22rem;
    }
    @media (max-width: 1280px) {
        left: 19.2rem;
    }
    top: 74px;
    z-index: 1200;
    border-right: 1px solid rgba(22, 55, 88, .15);
    border-left: 1px solid rgba(22, 55, 88, .15);
    border-bottom: 1px solid rgba(22, 55, 88, .15);
    min-height: 530px;

    .ft-button {
        margin-bottom: 12px;

        &__button {
            font-size: $p-large-size !important;
            color: $ft-brand-primary-blue;
            padding: 0;
            margin-bottom: 4px;

            .ft-icon {
                font-size: $p-size;
            }
        }

        .expanded-drawer {
            &__title {
                color: #21223b;
                margin-bottom: $space-sm;
            }
        }
    }
}

// annuity drawer

.annuity-drawer-text {
margin: 1.5rem 0;
}

.guaranteed-income-popover {
    margin-bottom: .8em;
    i {
        color: $ft-primary;
    }
}

.flex-split {
    gap: 10px;

    & > * {
        flex: 1;
    }
}

.hr-seperator {
    border-top: 1px solid #a4a4a4;
}

.annuity-income-input {
    display: flex;
    gap: 10px;
    flex: 3 !important;
    padding: 0!important;
    min-width: 45%;

    .input-field {
        margin-bottom: 0;
    }

    .plus-minus-btns {
        display: flex;
        flex-direction: column;
        gap: 14px;
        justify-content: center;
        align-items: center;

        .plus-minus {
            padding: 1px;
            line-height: 6px;
            color: $ft-brand-primary-blue !important;
            border: 1px $ft-brand-primary-blue solid;
            border-radius: 20px;
            height: 18px;
            width: 18px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: center;
        }
    }
}

.annuity-income-label {
    flex: 2 !important;
}

.income-gap {
    display: flex;
    flex: 3 !important;
    align-items: center;
    gap: 22px;

    & > div {
        position: relative;
        left: -20px;
    }

    .animated-arrow {
        left: -10px;
        opacity: 0;
        animation-duration: 1s;
        animation-delay: 2s;
        animation-name: slide-arrow;
        animation-fill-mode: forwards;

        i {
            font-size: 30px;
            color: gray;
        }
    }

    .animated-text {
        left: -20px;
        opacity: 0;
        animation-duration: 1s;
        animation-delay: 2s;
        animation-name: slide-text;
        animation-fill-mode: forwards;

        &__title {
            color: $ft-teal-3;
            font-weight: bold;
        }
    }
}

.expenditure-title,
.income-title {
    position: relative;
    margin-top: 12px;
    margin-bottom: 5px;
    opacity: 0;
    bottom: -5px;
    animation-duration: 1s;
    animation-name: title-anim;
    animation-fill-mode: forwards;

    i {
        background-color: $ft-sky-blue-3;
        padding: 5px;
        border-radius: 20px;
        color: white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
    }
}

.animated-expenditure-line {
    width: 70%;
    > div {
        width: var(--series-width)
    }
    .expenditure-line {
        width: 0;
        height: 10px;
        background-color: $ft-sky-blue-3;
        animation-duration: 1s;
        animation-name: line-anim;
        animation-fill-mode: forwards;
    }
}

.income-title {
    animation-delay: 1s;
    i {
        background-color: $ft-dark-blue;
    }
}

.animated-income-line {
    display: flex;
    width: 70%;
    > div {
        width: var(--series-width)
    }
    .income-line {
        height: 10px;
        width: 0;
        background-color: $ft-dark-blue;
        animation-duration: 1s;
        animation-delay: 1s;
        animation-name: line-anim;
        animation-fill-mode: forwards;
    }

    .income-gap {
        height: 10px;
        width: 0;
        background-color: $ft-orange-1;
        animation-duration: 1s;
        animation-delay: 2s;
        animation-name: line-anim;
        animation-fill-mode: forwards;
    }
}

@keyframes title-anim {
    from {
        bottom: -5px;
        opacity: 0;
    }

    to {
        bottom: 0;
        opacity: 1;
    }
}

@keyframes slide-arrow {
    from {
        opacity: 0;
        left: -10px;
    }

    to {
        opacity: 1;
        left: 0;
    }
}

@keyframes slide-text {
    from {
        opacity: 0;
        left: -20px;
    }

    to {
        opacity: 1;
        left: 0;
    }
}

@keyframes line-anim {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

.health-persona {
    display: flex;
    padding: 12px;
    background-color: $ft-light-grey;
    margin-top: 1rem;
    margin-bottom: 10px;
    color: $ft-dark-blue;

    & > div:nth-child(1) {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 62px;
        }
    }

    & > div:nth-child(2) {
        flex: 3;
    }
}

.client-profile-btn::ng-deep button {
    text-decoration: underline;
}

.warning-box {
    display: flex;
    border: solid 1px #f7bc00;
    padding: $space-xs;

    i {
        color: #f7bc00;
        flex: 1;
        font-size: $space-md;
    }

    .small-text {
        font-size: $smallest-size;
        flex: 11;
    }
}

.drawer-section-seperator {
    opacity: 1;
    border-top: 1px solid #a4a4a4!important; 
    margin: 20px 0;
    border: 0;
    height: 0; 
}

.drawer-section-paragraph {
    margin: 3.2rem 0;
}