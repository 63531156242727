@import "src/theme/";

.side-nav-container {
	margin-left: 1rem;
	position: fixed;
	background-color: $ft-sky-blue-1;
	padding: 0;
	color: #212121 !important;
	border-radius: 20px;
	margin-top: 1.5rem;
	width: clamp(75px, 6vw, 90px);
	z-index: 1;
	:first-child {
		border-radius: 20px 20px 0 0;
	}
	:last-child {
		border-radius: 0 0 20px 20px;
	}
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
	.side-nav-container {
		margin-left: calc(16px + (100vw - 1200px) * 0.12);
	}
}
@media (min-width: 1601px) {
	.side-nav-container {
		margin-left: 64px;
	}
}

.side-nav-segments {
	padding: 0.85rem 0.25rem;
	background-color: $ft-sky-blue-1;
	cursor: pointer;
	line-height: 1.2rem;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	text-align: center;
	i {
		font-size: $h2-size;
	}
	p {
		font-size: $smallest-size;
		margin: 0.5rem;
	}
	&.active-segment {
		color: #fff;
		background-color: $ft-primary;
	}
	&:hover {
		color: $ft-white;
		opacity: 0.5;
	}
}
