@import "src/theme/";

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav:before,
.nav:after {
    display: table;
    content: " ";
}

.ft-tabs {
    width: 100%;

    h5 {
        font-weight: 700;
        margin: 0 0.5rem .8em 0;
    }

    .ft-tabs__ui-style-standard {
        width: 100%;

        .nav-tabs {
            border-bottom: none;
            display: flex;
            justify-content: space-between;
            box-shadow: inset 0 -2px #fff;
            color: #333;
            cursor: pointer;
        }

        .nav-tabs>li>a {
            background-color: $ft-light-grey;
            border: 1px solid $ft-grey-1;
            color: $ft-black;

            &:hover {
                background-color: $qf-color-content-background;
                color: $qf-text-color;
            }
        }

        .nav-tabs>li {
            flex: 1;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
            }
        }

        .nav-tabs>li.active>a {
            background-color: $qf-color-content-background;
            border: 1px solid $qf-color-neutral;
            border-bottom-color: $qf-color-content-background;
            color: $qf-text-color;
            border-top: 4px solid $ft-sky-blue;
            font-weight: bold;
        }
    }

    .tab-content {
        background-color: $qf-color-content-background;
        padding: $qf-spacing-inset;
        border: 1px solid $qf-color-neutral;
        border-top-width: 0;

        .active i {
            color: $ft-sky-blue;
        }
    }
}