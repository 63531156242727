@import "src/theme";

.new-proposal-container {
	margin: 0;
	text-align: left;
	width: 700px;
	height: initial;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: relative;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	outline: 0;
	box-shadow: 0 5px 15px #00000080;
	padding: 3.5rem;
	z-index: 1300;
}

.new-proposal-footer {
	display: flex;
	align-items: center;
	gap: 1rem;
	padding-top: 2rem;
	.button-container {
		padding: 0;
	}
}
