@import "../../theme/defs";

.MuiTooltip-tooltip {
  background: $ft-black !important;
  color: $ft-white;
  padding: $space-sm !important;
  border-radius: 10px !important;
  line-height: $smaller-line-height;
  box-shadow: 0 4px 3px transparent;
  font-size: 12px !important;
}
.MuiTooltip-arrow {
  color: $ft-black !important;
}

// TODO:: CLEAN COMMENTED CODE

// .ftpopover {
//   .MuiTooltip-tooltip {
//     background: $ft-black !important;
//     // font-family: "TT Commons Pro";
//     color: $ft-white;
//     padding: $space-sm !important;
//     border-radius: 10px !important;
//     line-height: $smaller-line-height;
//     box-shadow: 0 4px 3px transparent;
//     font-size: 12px !important;
//   }
//   .MuiTooltip-arrow {
//     color: $ft-black !important;
//   }
// }
