@import 'src/theme';

@mixin drawer-input-width {
    max-width: 320px;
    width: 100%;
}

.drawer-content {
    &__title {
        &-container {
            display: flex;
            justify-content: space-between;
            background: $ft-teal-4;
            border-radius: $space-sm;
            padding: $space-sm;
            color: $qf-color-content-background;

            >* {
                margin-bottom: 0;
            }
        }
    }

    &__totalAmount,
    &__title {
        font-weight: 600;
        font-size: $p-size;
    }

    &__disclaimers {
        color: $ft-disclaimer-text-color;
        font-size: $smallest-size;
        margin-top: $space-md;
    }
}

.total-amount {
    padding: $space-sm 0;
    padding-right: $space-sm;

    h6 {
        padding-bottom: $space-sm;
        margin-bottom: $space-sm;
        border-bottom: 1px solid rgb(22, 55, 88, .15);
    }

    qf-label-value::ng-deep {
        .qf-label-value__label {
            color: $ft-grey-4;
        }
    }
}

.breakdown-block {
    border-bottom: 1px solid $qf-color-neutral-border;
    padding: $space-md 0;

    // &:nth-last-child(1) {
    //     border-bottom: 0;
    // }

    &__title {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: $smallest-size;
        font-weight: bold;

        &-container {
            display: flex;
            justify-content: space-between;
            margin-bottom: $space-sm;
        }
    }

    .button-container {
        padding: 0 !important;
      }
}

.input-with-error {
    height: 77px;

    .ft-text {
        display: block;
        margin-top: 4px;
    }
}

.error-message {
    color: $ft-orange;
}

.modal-title {
    text-align: left;
    color: $ft-brand-primary-blue;
    margin-bottom: $space-md;
    font-weight: 700;
}

.monthly-annual-label {
    font-size: $p-size;
    text-transform: none;
    font-weight: normal;
}

.new-wealth-popup,
.life-event-popup,
.new-pension-popup,
.other-income-popup {

    .qf-dropdown,
    .qf-input-text,
    .qf-input-number {
        @include drawer-input-width;
    }
}



$ft-white: #fff;

$smallest-size: 0.813rem; //  13px
$qf-color-neutral-border: #a4a4a4;
$space-xs: 0.5rem; //   8px
$space-sm: 1rem; //  16px
$space-md: 1.5rem; //  24px
$space-lg: 2rem; //  32px
$space-xl: 3.5rem; //  56px
$space-xxl: 4.5rem; //  72px
$space-xxxl: 9rem; // 144px

// Black / White
$ft-white: #fff;
$ft-light-grey: #f4f4f4;
$ft-grey-1: #e6e6e6;
$ft-grey-2: #bfbfbf;
$ft-grey-3: #8c8c8c;
$ft-grey-4: #595959;
$ft-black: #212121;

// Inputs
$qf-input-display: block;
$qf-input-border-radius: 0;
$qf-input-border: 1px solid #c9c9c9;
$qf-input-bg: #fff;
$qf-input-bg-hover: #fff;
$qf-input-label-color: #333;
$qf-input-line-height: 1.3em;
$qf-input-padding: 36px 16px 8px 16px;

// Input group
$qf-input-group-background: #fafafa;

// Blue
$ft-sky-blue-1: #ddeaff;
$ft-sky-blue-2: #91b9ff;
$ft-sky-blue-3: #5291ff;
$ft-sky-blue: #3769ff;
$ft-sky-blue-4: #1446e1;

$ft-brand-primary-blue: $ft-sky-blue;
$ft-dark-blue: $ft-sky-blue-4;
$ft-red-1: #e60000;
$ft-disclaimer-text-color: $ft-black;

// Colors
$qf-color-primary: #007acc;
$qf-color-primary-background: $ft-brand-primary-blue;
$qf-color-secondary: #ddd;
$qf-color-neutral: #e8e8e8;
$qf-color-neutral-darker: darken($qf-color-neutral, 20%);
$qf-color-neutral-lighter: lighten($qf-color-neutral, 20%);
$qf-color-neutral-background: #fafaf9;
$qf-color-content-background: #fff;
$qf-color-neutral-border: #a4a4a4;

// TYPOGRAPHY
$standard-line-height: 150%;
$smaller-line-height: 140%;
$h1-size: 2.375rem; //  38px
$h2-size: 1.75rem; //  28px
$h3-size: 1.563rem; //  25px
$h4-size: 1.3125rem; //  21px
$h5-size: 1.3125rem; //  21px
$h6-size: 1.2rem; //  19.2px
$p-large-size: 1.2rem; //  19.2px // not in the design language, delete?
$p-size: 1rem; //  16px
$smallest-size: 0.813rem; //  13px


$default-font-size: 16px;

// SPACING
$space-xs: .5rem;   //   8px
$space-sm: 1rem;    //  16px
$space-md: 1.5rem;  //  24px
$space-lg: 2rem;    //  32px
$space-xl: 3.5rem;  //  56px
$space-xxl: 4.5rem; //  72px
$space-xxxl: 9rem;  // 144px

// Blue
$ft-sky-blue-1: #ddeaff;

$ft-sky-blue-2: #91b9ff;
$ft-sky-blue-3: #5291ff;
$ft-sky-blue: #3769ff;
$ft-sky-blue-4: #1446e1;

// Green
$ft-green-2: #81bb00;

// Teal
$ft-teal: #00bfb3;
$ft-teal-1: #d3fbf7;
$ft-teal-2: #72dbd5;
$ft-teal-3: #00a096;
$ft-teal-4: #00847d;

// Black / White
$ft-white: #fff;
$ft-light-grey: #f4f4f4;
$ft-grey-1: #e6e6e6;
$ft-grey-2: #bfbfbf;
$ft-grey-3: #8c8c8c;
$ft-grey-4: #595959;
$ft-black: #212121;

// Purple
$ft-purple1: #e7dffe;
$ft-purple2: #b39af5;
$ft-purple3: #8760ef;
$ft-purple: #6730e3;
$ft-purple4: #4b00b6;

// Orange
$ft-orange-1: #ffe0d2;
$ft-orange-2: #ff9665;
$ft-orange-3: #ff6035;
$ft-orange: #e24100;
$ft-orange-4: #be3200;

// Fuchsia
$ft-fuchsia-1: #fae4fd;
$ft-fuchsia-2: #f0b4f9;
$ft-fuchsia-3: #cb6eee;
$ft-fuchsia: #c042ea;
$ft-fuchsia-4: #a525d0;

// Berry
$ft-berry-1: #ffdde6;
$ft-berry-2: #f589a2;
$ft-berry-3: #f04f74;
$ft-berry: #ff0f52;
$ft-berry-4: #dc0546;

// Button states
$ft-primary-button-hover: #345edd;
$ft-secondary-button-hover: #ededed;

// TYPOGRAPHY
$standard-line-height: 150%;
$smaller-line-height: 140%;
$h1-size: 2.375rem;     //  38px
$h2-size: 1.75rem;      //  28px
$h3-size: 1.563rem;    //  25px
$h4-size: 1.3125rem;    //  21px
$h5-size: 1.3125rem;    //  21px
$h6-size: 1.2rem;       //  19.2px
$p-large-size: 1.2rem;  //  19.2px // not in the design language, delete?
$p-size: 1rem;          //  16px
$smallest-size: .813rem;//  13px


.pdf-content {
    padding: 0 $space-lg;

    &-container {
        width: 1200px;
        margin: auto;
        background: $qf-color-content-background;
    }
}

.steps {
    &__title {
        margin-bottom: 0;
        justify-content: center;
        display: flex;
        flex-direction: column;

        &-container {
            display: flex;
            padding: $space-sm 0;
        }

        &__icon {
            color: $ft-white;
            background-color: $ft-sky-blue;
            margin-right: $space-md;

            &__number {
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                text-align: center;
                font-size: $space-sm;
            }
        }

        &__label {
            color: $ft-sky-blue;
            text-decoration: underline;
        }
    }
}