@import "../../shared.scss";

.pdf-header {
    padding: $space-lg;
    border-bottom: 1px solid $ft-grey-3;
    display: flex;
    justify-content: space-between;

    &.transaction-pdf {
        margin: 0 calc(#{ $space-lg} * -1);
    }

    &__title {
        margin: 0;
        font-weight: 700;
    }

    &__contact {
        font-size: $smallest-size;
        color: $ft-grey-3;
    }

    &__qr-code {
        &-container {
            max-width: 30%;
            text-align: end;
            display: inline-flex;
        }

        &__img {
            margin-left: $space-sm;

            img {
                width: 100px;
            }
        }
    }
}