@import "src/theme";
@import "../../theme/defs";

.header-container {
	box-shadow: $header-box-shadow;
	border-bottom: 1px solid #eeeeee;
	cursor: pointer;
	height: 4.5rem;
	display: flex;
	justify-content: space-between;
	position: relative;
	z-index: 5;
	background: $ft-white;
}

.ft-logo {
	height: 3rem;
	padding: 0.7rem 1.6rem;
	// float: left;
}

.header-unauthenticated {
	padding: 0.5rem 1.5rem;
	margin-right: 1rem;
	display: flex;
	justify-content: flex-end;
	cursor: text;
}

.header-unauthenticated>p {
	color: $ft-grey-4;
}

.header-authenticated {
	display: flex;
}

.content-justified {
	justify-content: space-between;
	width: 100%;
}

.header-navigation-links {
	display: flex;
	width: max-content;

	#support-btn:hover {
		border: 1px solid $ft-sky-blue;
	}
}

.dropdownmenu {
	box-shadow: 0 3px 7px 2px #0000001f;
	width: 179px;
	transition: opacity .03s;
	font-size: $space-sm;
	display: flex;
	align-items: center;
	flex-direction: column;

	>li .MuiMenuItem-root .Mui-focusVisible {
		background-color: transparent;
	}

	.link {
		font-size: $space-sm;
		padding: $space-xs;

		.small {
			margin-left: $space-xs;
		}
	}
}

// li .MuiMenuItem-root {
// 	background-color: transparent;
	
// 	&:hover{
// 		background-color: transparent;
// 	}
// }

.header-buttons {
	cursor: pointer;
	color: $ft-primary-focus;
	padding: 1.5rem 2rem 1rem;

	span {
		font-size: 1.2rem;
		vertical-align: middle;
		padding: 0 1rem;
	}

	i {
		font-size: 1.5rem;
		font-weight: 400;
	}

	.small {
		font-size: 1rem;
		font-weight: 600;
	}
}

.is-active {
	background-color: $ft-primary;
	color: $qf-color-secondary;
	cursor: default;
}

.client-info {
	display: flex;
	flex-direction: column;
	color: $ft-text-color;
	height: 100%;
	padding: 0.5rem;
}