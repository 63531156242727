@import "src/theme/";

.proposals-container {
	padding: 0 $space-xxl $space-xs;
	padding-left: $space-xxxl;
	margin: $space-sm auto;
	max-width: $ft-container-width-small;
}

.proposals-header {
	display: flex;
	.page-headings {
		flex-grow: 1;
		margin-top: $space-xs;
		margin-right: $space-md;
	}
}

.floating-header {
	position: fixed;
	padding: $space-sm;
	justify-content: space-between;
	top: .5rem;
	display: flex;
	flex-grow: 1;
	pointer-events: none;
	background: $ft-white;
	box-shadow: 0 2px 4px #00000012;
	border-radius: .5rem;
	opacity: 0;
	transition: opacity ease .25s;
	z-index: 3;
	width: calc(100% - 10rem - 75px);
	max-width: 1220px;
}

.floating-header.shown {
	opacity: 1;
	pointer-events: initial;
}

.floating-header__client-name {
	display: inline-flex;
	margin: auto 0;
}

.floating-header__client-name {
	display: inline-flex;
	margin: auto 0;
}