@import "../../theme/defs";

.radial {
    position: relative;
    margin: auto;
    width: 280px;
    height: 140px;
    border-top-left-radius: 140px;
    border-top-right-radius: 140px;
    border-bottom: 0;
    background: conic-gradient(from 270deg at 50% 100%, #d3fbf7 0deg, $ft-teal-3 180deg);

    &::after {
        content: '';
        background-color: white;
        position: absolute;
        width: 190px;
        height: 95px;
        bottom: 0;
        left: 45px;
        border-top-left-radius: 95px;
        border-top-right-radius: 95px;
        border: inherit;
    }

    .radial-layer-1 {
        position: absolute;
        z-index: 1;
        left: 75px;
        bottom: 0;
        width: 130px;
        height: 65px;
        border-top-left-radius: 65px;
        border-top-right-radius: 65px;
        background: rgba(180, 232, 236, .19);

        &::after {
            content: '';
            background-color: $ft-sky-blue-1;
            position: absolute;
            width: 90px;
            height: 45px;
            left: 20px;
            bottom: 0;
            border-top-left-radius: 45px;
            border-top-right-radius: 45px;
        }
    }

    .radial-layer-2 {
        position: absolute;
        z-index: 1;
        left: 115px;
        bottom: 0;
        width: 50px;
        height: 25px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        background: $ft-teal-2;

        &::after {
            content: '';
            width: 22px;
            height: 22px;
            background: #1d6d69;
            border-radius: 22px;
            position: absolute;
            left: 14px;
            bottom: -11px;
        }
    }

    .hand {
        border-bottom: 140px solid #1d6d69;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        top: 20px;
        width: 2px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform-origin: 0 120px;
        transition: transform .5s;
        z-index: 1;

        &::after {
            content: '';
            background: white;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            top: 115px;
            position: absolute;
            left: -4px;
        }
    }

    &-sm {
        width: 180px;
        height: 90px;
        border-top-left-radius: 90px;
        border-top-right-radius: 90px;

        &::after {
            width: 116px;
            height: 58px;
            left: 32px;
            border-top-left-radius: 58px;
            border-top-right-radius: 58px;
        }

        .radial-layer-1 {
            width: 80px;
            height: 40px;
            left: 50px;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;

            &::after {
                width: 56px;
                height: 28px;
                left: 12px;
                border-top-left-radius: 28px;
                border-top-right-radius: 28px;
            }
        }

        .radial-layer-2 {
            width: 32px;
            height: 16px;
            left: 74px;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;

            &::after {
                width: 14px;
                height: 14px;
                border-radius: 14px;
                left: 9px;
                bottom: -7px;
            }
        }

        .hand {
            border-bottom: 90px solid $ft-dark-blue;
            border-left: 2.5px solid transparent;
            border-right: 2.5px solid transparent;
            top: 14px;
            width: 2px;
            transform-origin: 0 75px;

            &::after {
                width: 6px;
                height: 6px;
                border-radius: 6px;
                top: 72px;
                left: -3px;
            }
        }
    }
}

// Success Probablity Radial 
.success-probablity-radial {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    width: 164px;
    height: 164px;
    border-radius: 200px;
    border-bottom: 0;
    background: conic-gradient(from 216deg, #ff6f69, #ffcf66 50%, #88d8b0 80%, $ft-white 80%);

    &.radial--pdf {
        width: 214px;
        height: 214px;
        background-color: var(--background-color);

        &::before {
            width: 125px;
            height: 125px;
            background-color: transparent;
        }

        .hand {
            height: 85px;
        }
    }

    &__bottom-part {
        position: absolute;
        bottom: 0;
        height: 0;
        margin-bottom: -1px;
        width: 73%;
        border-bottom: 62px solid $ft-white;
        border-left: 44px solid transparent;
        border-right: 45px solid transparent;
    }

    &::before {
        content: '';
        background-color: $ft-white;
        position: absolute;
        width: 100px;
        height: 100px;
        transform-origin: right;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        border-radius: 100px;
        z-index: 2;
    }

    span {
        z-index: 3;
    }

    .hand {
        position: absolute;
        width: 6px;
        height: 34px;
        background-color: $ft-white;
        box-shadow: 0 -1px 6px 1px rgba(17, 17, 17, .15);
        z-index: 1;
        transform: var(--transform-degree);

        &::after {
            content: '';
            background: $ft-white;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            top: 115px;
            position: absolute;
            left: -4px;
        }
    }
}

.proposal-pdf-summary {
    display: flex;
    gap: $space-xl;
    padding: 0 2rem;
    &__chart__labels {
        z-index: 2;
        width: 125px;
        height: 125px;
        background: $ft-white;
        display: flex;
        border-radius: 100px;
        flex-direction: column;

        > * {
            text-align: center;
            margin: 0 auto auto;
        }

        h5 {
            font-weight: 500;
            margin: auto auto 0;
        }
        h6 {
            font-weight: 700;
        }
    }
    &__content-container {
        h6 {
            font-weight: 700;
        }
    }
 
}


.radial-pdf-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    width: 164px;
    height: 164px;
    border-radius: 200px;
    border-bottom: 0;
    background: conic-gradient(from 216deg, #ff6f69, #ffcf66 50%, #88d8b0 80%, $ft-white 80%);

    &.radial--pdf {
        width: 214px;
        height: 214px;
        background-color: var(--background-color);

        &::before {
            width: 125px;
            height: 125px;
            background-color: transparent;
        }

        .hand {
            height: 85px;
        }
    }

    &__bottom-part {
        position: absolute;
        bottom: 0;
        height: 0;
        margin-bottom: -1px;
        width: 35%;
        border-bottom: 62px solid $ft-white;
        border-left: 44px solid transparent;
        border-right: 45px solid transparent;
    }

    &::before {
        content: '';
        background-color: $ft-white;
        position: absolute;
        width: 100px;
        height: 100px;
        transform-origin: right;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        border-radius: 100px;
        z-index: 2;
    }

    span {
        z-index: 3;
    }

    .hand {
        position: absolute;
        width: 6px;
        height: 34px;
        background-color: $ft-white;
        box-shadow: 0 -1px 6px 1px rgba(17, 17, 17, .15);
        z-index: 1;
        transform: var(--transform-degree);

        &::after {
            content: '';
            background: $ft-white;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            top: 115px;
            position: absolute;
            left: -4px;
        }
    }
}