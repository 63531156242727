@import "src/theme/";

.ft-page-container {
  padding: $space-sm $space-xl;
  margin: $space-xs auto;
  max-width: 1220px;

  &-update {
    padding-left: 8rem;

    .ftbox-body {
      padding: $space-lg 3rem;
    }
  }
}

/** Create Client Page Section */

.ft-page-block {
  max-width: 1220px;

  &__y-padded {
    padding-left: $space-sm;
    padding-right: $space-sm;
  }
  &__x-padded {
    padding-top: $space-sm;
    padding-bottom: $space-sm;
  }
}

.create-client-page {
  margin-left: 0;

  ._client-existed {
    color: $ft-berry-4;
  }
  .middle__control {
    margin-bottom: $space-md;

    a {
      cursor: pointer;
    }
  }
}

.display-elements-inline {
  display: flex;
  justify-content: space-between;
  gap: $space-sm;
}
.create-warning-msg {
  display: block;
  width: 49%;
  float: right;
  margin-bottom: $space-sm;

  & .message-bar__container {
    border: none;
    box-shadow: none;
    padding: 0;
    color: $ft-orange-3;
  }
}

._separator {
  border-color: $ft-grey-3;
  opacity: 0.5;
  clear: right;
}

.inside-input-field {
  position: absolute;
  right: 4px;
  top: -2px;
}

._contact-saved {
  margin-right: $space-md;
  color: green;
}

.bottom-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/** Update Profile Section */

// Profile Information

.proposal-profile-info {
  &-container {
    padding-bottom: $space-md;
    margin-bottom: $space-md;

    &:not(:nth-last-child(1)) {
      border-bottom: 1px solid $qf-color-neutral;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    > * {
      font-weight: 700;
      margin: 0 0 0.8em;
    }

    p {
      font-size: $smallest-size;
      margin-bottom: 0;
    }
  }

  &__value {
    padding: $space-xs $space-sm;
    flex-basis: 0;
    flex-grow: 0.33;

    &-label {
      font-size: $smallest-size;
    }

    &--group {
      display: flex;
      background: $ft-light-grey;
      padding: $space-xs;
      border-radius: 10px;
      width: 90%;
      margin-bottom: $space-xs;

      &:not(:nth-child(1)) {
        margin-top: $space-md;
      }
    }
  }
}

.profile-last-update-date {
  &__title {
    text-align: end;
    margin: 0;
    font-size: 11px;
  }

  &__date {
    font-weight: 400;
    margin: 0;
    font-size: $p-size;
  }
}

// Input label for static values
.label-model {
  padding: 8px $space-sm;

  &__label {
    font-size: 0.75rem;
    line-height: 1.3em;
  }

  &__value {
    font-size: $p-size;
    margin-top: 2px;
  }
}
