@charset "UTF-8";
@import "src/theme/";
@import "./theme/defs";
@import "./shared.scss";
@import url("./assets/icons/iconstyle.scss");

@font-face {
  font-family: "TT Commons Pro";
  font-style: normal;
  font-weight: normal;
  src: url("../src/assets/fonts/TTCommonsProRegular.otf") format("opentype"),
    url("../src/assets/fonts/TTCommonsProRegular.ttf") format("truetype");
}

@font-face {
  font-family: "TT Commons Pro";
  font-style: normal;
  font-weight: bold;
  src: url("../src/assets/fonts/TTCommonsProBold.otf") format("opentype");
}

html {
  font-family: "TT Commons Pro", sans-serif;
  font-size: $default-font-size;
}

body {
  line-height: $standard-line-height;
  letter-spacing: 0.03px;
  color: $ft-text-color;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "TT Commons Pro", sans-serif;
  line-height: $standard-line-height;
  font-weight: 400;
  margin: 0;
}

.small-text {
  font-size: 0.813rem;
}

.long-text-wrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 15em;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.low-opacity {
  opacity: .6;
}

.disabled-section {
  pointer-events: none;
}

.upside-down { 
  display: inline-block;
  transition: transform .15s;
  transform: rotate(180deg)!important;
}

.active-border { 
  border: 1px solid $ft-primary;
}

h1 {
  line-height: $smaller-line-height;
  font-size: $h1-size;
}

h2 {
  font-size: $h2-size;
}

h3 {
  font-size: $h3-size;
}

h4 {
  font-size: $h4-size;
}

h5 {
  font-size: $h5-size;
}

h6 {
  font-size: $h6-size;
}

.bold {
  font-weight: 700;
}

.demi-bold {
  font-weight: 600;
}

.small-font {
  font-size: $smallest-size;
}

.same-line {
  display: flex;
  gap: 5px;
  align-items: center;
}

.align-right {
  float: right;
}

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid $qf-color-primary;
  height: 0;
}

.error-message {
  color: $ft-red-1;
  margin: -10px 0 10px;
}

.MuiPaper-rounded {
  border-radius: 0;
}

// common styles

.ft-page-block {
  max-width: 1220px;

  &__y-padded {
    padding-left: $space-sm;
    padding-right: $space-sm;
  }

  &__x-padded {
    padding-top: $space-xs;
    padding-bottom: $space-xs;
  }
}

a {
  cursor: pointer;
  color: $ft-sky-blue;
  text-decoration: none;

  &:hover{
    color: #33adff
  }
}

.pdf-icon {
  background-size: cover;
  background-image: url('./assets/icons/raw-icons/pdf-icon.svg');
}

.ticket-icon {
  background-size: cover;
  background-image: url('./assets/icons/raw-icons/ticket-icon.svg');
}

.income-icon {
  background-image: url('./assets/icons/raw-icons/income.svg');
}

.outcome-icon {
  background-image: url('./assets/icons/raw-icons/outcome.svg');
}