@import "variables";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?kbx84u")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?kbx84u")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?kbx84u##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i.ft {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ft-proposals-1 {
  &::before {
    content: $proposals-1;
  }
}

.ft-add {
  &::before {
    content: $ft-add;
  }
}

.ft-alert {
  &::before {
    content: $ft-alert;
  }
}

.ft-arrowDown {
  &::before {
    content: $ft-arrow-down;
  }
}

.ft-arrowUp {
  &::before {
    content: $ft-arrow-up;
  }
}

.ft-attachement {
  &::before {
    content: $ft-attachement;
  }
}

.ft-audio {
  &::before {
    content: $ft-audio;
  }
}

.ft-beyondBullsBears {
  &::before {
    content: $ft-beyond-bulls-bears;
  }
}

.ft-bookmarkSelected {
  &::before {
    content: $ft-bookmark-selected;
  }
}

.ft-boomarkDefault {
  &::before {
    content: $ft-boomark-default;
  }
}

.ft-calculator {
  &::before {
    content: $ft-calculator;
  }
}

.ft-calendar {
  &::before {
    content: $ft-calendar;
  }
}

.ft-carrotDown {
  &::before {
    content: $ft-carrot-down;
  }
}

.ft-caret-down {
  &::before {
    content: $ft-carrot-down;
  }
}

.ft-carrotleft {
  &::before {
    content: $ft-carrotleft;
  }
}

.ft-carrotRight {
  &::before {
    content: $ft-carrot-right;
  }
}

.ft-carrotUp {
  &::before {
    content: $ft-carrot-up;
  }
}

.ft-cart {
  &::before {
    content: $ft-cart;
  }
}

.ft-chart {
  &::before {
    content: $ft-chart;
  }
}

.ft-chat {
  &::before {
    content: $ft-chat;
  }
}

.ft-checkmark {
  &::before {
    content: $ft-checkmark;
  }
}

.ft-circleDownCarrot {
  &::before {
    content: $ft-circle-down-carrot;
  }
}

.ft-clock {
  &::before {
    content: $ft-clock;
  }
}

.ft-confirm {
  &::before {
    content: $ft-confirm;
  }
}

.ft-cross {
  &::before {
    content: $ft-cross;
  }
}

.ft-document {
  &::before {
    content: $ft-document;
  }
}

.ft-download {
  &::before {
    content: $ft-download;
  }
}

.ft-edit {
  &::before {
    content: $ft-edit;
  }
}

.ft-eyeHide {
  &::before {
    content: $ft-eye-hide;
  }
}

.ft-eyeReveal {
  &::before {
    content: $ft-eye-reveal;
  }
}

.ft-featureRibbon {
  &::before {
    content: $ft-feature-ribbon;
  }
}

.ft-flag {
  &::before {
    content: $ft-flag;
  }
}

.ft-folderInactive {
  &::before {
    content: $ft-folder-inactive;
  }
}

.ft-gift {
  &::before {
    content: $ft-gift;
  }
}

.ft-grid {
  &::before {
    content: $ft-grid;
  }
}

.ft-home {
  &::before {
    content: $ft-home;
  }
}

.ft-info {
  &::before {
    content: $ft-info;
  }
}

.ft-list {
  &::before {
    content: $ft-list;
  }
}

.ft-lock {
  &::before {
    content: $ft-lock;
  }
}

.ft-mail {
  &::before {
    content: $ft-mail;
  }
}

.ft-meatballs {
  &::before {
    content: $ft-meatballs;
  }
}

.ft-menu {
  &::before {
    content: $ft-menu;
  }
}

.ft-message {
  &::before {
    content: $ft-message;
  }
}

.ft-newWindow {
  &::before {
    content: $ft-new-window;
  }
}

.ft-openNewTab {
  &::before {
    content: $ft-open-new-tab;
  }
}

.ft-output {
  &::before {
    content: $ft-output;
  }
}

.ft-pause {
  &::before {
    content: $ft-pause;
  }
}

.ft-payment {
  &::before {
    content: $ft-payment;
  }
}

.ft-phone {
  &::before {
    content: $ft-phone;
  }
}

.ft-play {
  &::before {
    content: $ft-play;
  }
}

.ft-playBox {
  &::before {
    content: $ft-play-box;
  }
}

.ft-podcast {
  &::before {
    content: $ft-podcast;
  }
}

.ft-print {
  &::before {
    content: $ft-print;
  }
}

.ft-question {
  &::before {
    content: $ft-question;
  }
}

.ft-reset {
  &::before {
    content: $ft-reset;
  }
}

.ft-save {
  &::before {
    content: $ft-save;
  }
}

.ft-search {
  &::before {
    content: $ft-search;
  }
}

.ft-shield {
  &::before {
    content: $ft-shield;
  }
}

.ft-starActive {
  &::before {
    content: $ft-star-active;
  }
}

.ft-starInactive {
  &::before {
    content: $ft-star-inactive;
  }
}

.ft-subtract {
  &::before {
    content: $ft-subtract;
  }
}

.ft-swipe {
  &::before {
    content: $ft-swipe;
  }
}

.ft-thinks {
  &::before {
    content: $ft-thinks;
  }
}

.ft-triangleDown {
  &::before {
    content: $ft-triangle-down;
  }
}

.ft-triangleUp {
  &::before {
    content: $ft-triangle-up;
  }
}

.ft-upload {
  &::before {
    content: $ft-upload;
  }
}

.ft-cog {
  &::before {
    content: $ft-cog;
  }
}

.ft-drawerAnnuity {
  &::before {
    content: $ft-drawer-annuity;
  }
}

.ft-drawerBenefitsIncome {
  &::before {
    content: $ft-drawer-benefits-income;
  }
}

.ft-handMoney {
  &::before {
    content: $ft-hand-money;
  }
}

.ft-drawerExpenditure {
  &::before {
    content: $ft-drawer-expenditure;
  }
}

.ft-drawerRisk {
  &::before {
    content: $ft-drawer-risk;
  }
}

.ft-drawerTotalWealth {
  &::before {
    content: $ft-drawer-total-wealth;
  }
}

.ft-dcPension {
  &::before {
    content: $ft-dc-pension;
  }
}

.ft-statePension {
  &::before {
    content: $ft-state-pension;
  }
}

.ft-pound-arrows {
  &::before {
    content: $ft-pound-arrows;
  }
}

.ft-value {
  &::before {
    content: $ft-value;
  }
}

.ft-checkmark2 {
  &::before {
    content: $ft-checkmark2;
  }
}

.ft-copy {
  &::before {
    content: $ft-copy;
  }
}

.ft-amount {
  &::before {
    content: $ft-amount;
  }
}

.ft-annuity {
  &::before {
    content: $ft-annuity;
  }
}

.ft-zoom {
  &::before {
    content: $ft-zoom;
  }
}

.ft-download-2 {
  &::before {
    content: $ft-download-2;
  }
}

.ft-remove {
  &::before {
    content: $ft-remove;
  }
}

.ft-update-profile {
  &::before {
    content: $ft-update-profile;
  }
}

.ft-finger {
  &::before {
    content: $ft-finger;
  }
}

.ft-arrow {
  &::before {
    content: $ft-arrow;
  }
}

.ft-average-health {
  &::before {
    content: $ft-average-health;
  }
}

.ft-healthy {
  &::before {
    content: $ft-healthy;
  }
}

.ft-income {
  &::before {
    content: $ft-income;
  }
}

.ft-new-client {
  &::before {
    content: $ft-new-client;
  }
}

.ft-outcome {
  &::before {
    content: $ft-outcome;
  }
}

.ft-smoker {
  &::before {
    content: $ft-smoker;
  }
}

.ft-basket {
  &::before {
    content: $ft-basket;
  }
}

.ft-warning {
  &::before {
    content: $ft-warning;
  }
}

.ft-x-switch {
  &::before {
    content: $ft-x-switch;
  }
}

.ft-save2 {
  &::before {
    content: $ft-save2;
  }
}

.ft-stagg24x24 {
  &::before {
    content: $ft-stagg24x24;
  }
}

.ft-checkmark3 {
  &::before {
    content: $ft-checkmark3;
  }
}

.ft-secure {
  &::before {
    content: $secure;
  }
}

.ft-flexible {
  &::before {
    content: $flexible;
  }
}

.ft-dynamic {
  &::before {
    content: $dynamic;
  }
}

.ft-launchpad-step {
  &::before {
    content: $launchpad-step;
  }
}

.ft-email {
  &::before {
    content: $email;
  }
}

.ft-error {
  &::before {
    content: $error;
  }
}

.ft-flexible1 {
  &::before {
    content: $flexible1;
  }
}

.ft-growth {
  &::before {
    content: $growth;
  }
}

.ft-income-calculations-2 {
  &::before {
    content: $income-calculations-2;
  }
}

.ft-linked-in {
  &::before {
    content: $linked-in;
  }
}

.ft-logout {
  &::before {
    content: $logout;
  }
}

.ft-profile {
  &::before {
    content: $profile;
  }
}

.ft-proposal-complete {
  &::before {
    content: $proposal-complete;
  }
}

.ft-proposal-in-progress {
  &::before {
    content: $proposal-in-progress;
  }
}

.ft-view {
  &::before {
    content: $view;
  }
}
