@import "src/theme/";

.launchpad-container {
	padding: $space-xs $space-xxl;
	padding-left: $space-xxxl;
	margin: $space-sm auto;
	max-width: $ft-container-width-small;
  display: flex;
  flex-direction: column;
}


.launchpad-summary {
  display: flex;
  padding: $space-lg $space-xl;
  background-color: $ft-teal-4;
  border-radius: 15px;
  color: $ft-white;

  > .launchpad-flex-split {
    display: flex;
    width: 100%;
  }

  .launchpad-summary-left-side {
      flex: 5;

      > h5 {
          margin-bottom: $space-lg;
          color: $ft-white;
      }

      > .launchpad-flex-split {
          display: flex;
          gap: 32px;
          width: 100%;
      }

      .launchpad-retirement-income {
          &-container {
              &:not(:last-child) {
                  border-right: 1px solid $ft-white;
                  padding-right: 1rem;
              }

              flex: 33%;
              display: flex;
              flex-direction: column;
          }

          &-prop {
              flex: 100%;
              display: flex;
              flex-direction: row;
              gap: 1rem;

              &:not(:last-child) {
                  margin-bottom: $space-md;
              }

              span {
                  flex: 50%;
                  font-size: $smallest-size;

                  &:first-child {
                      font-weight: bold;
                  }
              }
          }
      }
  }

  .launchpad-next-step-icon {
      color: $ft-white;
      float: right;
      font-size: 60px;
  }
}

.launchpad-goto-links {
  padding-top: 1rem;
  span {
    padding-right: 1.5rem;
    color: $ft-primary-focus;
    text-decoration: underline;
    cursor: pointer;
    i {
      margin-right: 8px;
    }
  }
}


.launchpad-flex-split {
  .retirement-income {
      &-container:not(:last-child) {
          border-right: 1px solid $ft-white;
          display: flex;
          flex-direction: column;
      }

      &-prop:not(:last-child) {
          margin-bottom: $space-md;
          flex: 100%;
          display: flex;
          flex-direction: row;
          gap: 1rem;
      }
  }
}

.launchpad-main-content {
  display: flex;
  gap: $space-md;

  > div:nth-child(1) {
      flex: 1;
  }

  > div:nth-child(2) {
      flex: 2;
  }

  .launchpad-left-card {
      padding: $space-sm $space-lg;
      border: solid 1px #e8e8e8;
      border-radius: 15px;
      background-color: white;
      margin: $space-sm 0;

      h6 {
          border-bottom: 1px #21212125 solid;
          padding-bottom: 10px;
      }
  }

  .launchpad-right-card {
    .quotation-info {
        display: flex;
        flex-direction: column;
        div {
            margin-bottom: 0.5rem;
            margin-top: 0.4rem;
        }
    }
      h6 {
          color: $ft-sky-blue-4;
      }

      p {
          margin-bottom: $space-lg;
      }

      > div:nth-child(1) {
          flex: 1;
          i {
              font-size: 50px;
              color: $ft-primary-focus;
          }
      }

      > div:nth-child(2) {
          flex: 8;
      }

      .recalculate-btn,
      .update-quotation-btn,
      .download-btn {
          margin-right: $space-xs;
          >.recalc-icon {
            padding-right: 5px;
          }
      }

      &.disabled {
          opacity: .8;
      }

      .info-alert {
          font-size: $smallest-size;
          color: $ft-orange-3;
          margin-top: $space-sm;

          i {
              color: inherit;
          }

          u {
              cursor: pointer;
          }
      }

      .quotation-selection {
          &__content {
              display: flex;

              &__control {
                  flex: 70%;
              }

              &__logo {
                  flex: 30%;
                  margin: 0 $space-sm;

                  &__remark {
                      font-size: $smallest-size;
                  }

                  img {
                      width: 80%;
                      height: fit-content;
                  }
              }
          }

          p {
              font-size: $smallest-size;
          }
      }
  }
}

.launchpad-right-card {
  display: flex;
  border-radius: 15px;
  background-color: rgba(219, 239, 251, .35);
  padding: $space-md;
  color: #163758;
  margin: $space-lg  0;

  &.completed-proposal__container {
      flex-direction: column;

      i {
          font-size: 3.5rem;
          margin-bottom: $space-md;
          color: $ft-purple;
      }

      h3 {
          max-width: 50%;
          color: $ft-sky-blue-4;
      }

      .completed-proposal__content {
          display: flex;
          gap: 3.5rem;

          > * {
              flex: 50%;
          }
      }
  }
}

.launchpad-key-value-line {
  display: flex;
  margin: $space-sm 0;

  > * {
      flex: 1;
  }

  span:nth-child(1) {
      flex: 3;
  }

  span:nth-child(2) {
      text-align: right;
      font-weight: bold;
  }
}

.launchpad-disclaimer {
  margin-top: $space-lg;
  font-size: $smallest-size;
  color: $ft-disclaimer-text-color;
}