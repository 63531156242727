@import "../../shared.scss";

.pdf-proposal-info {
    padding: $space-lg;
    display: flex;
    h6 {
        font-weight: 700;
    }
    &.transaction-pdf {
        margin: 0 calc(#{ $space-lg} * -1);
    }

    &__advisor-info,
    &__proposal-info {
        flex-basis: 0;
        flex-grow: 1;

        &__name,
        &__create-date {
            margin-left: $space-sm;
            font-weight: 200;
        }
    }
}

