@import "src/theme";

.landing-container {
	padding: 1rem 3.5rem;
	margin: 1rem auto;
	max-width: $ft-container-width-small;
}

.welcome-title {
	display: flex;
	background: $ft-primary-focus;
	color: $ft-light-grey;
	border-radius: 10px;
	box-shadow: $card-box-shadow;
	padding: 0.75rem;

	p {
		font-size: 1.5rem;
		margin: 0.5rem;
		width: 75%;
	}
}

.ft-finger-icon {
	border-radius: 50%;
	margin: 0.12rem;
	width: 2.6rem;
	height: 2.6rem;
}

.last-logged-in {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	p {
		font-size: 1rem;
		width: 100%;
		text-align: right;
		margin: 0;
	}
}

.explore-heading {
	color: $ft-primary-focus;
	font-weight: 700;
	margin-bottom: 1rem;
}

.explore-heading-cards {
	display: flex;
	flex-direction: row;
	align-items: stretch;

	&:active,
	&:visited {
		outline: 3px solid $ft-black;
	}

	& :hover {
		._article-content>* {
			text-decoration: underline;
			color: $ft-sky-blue-3;
		}
	}

	._article-thumbnail {
		height: 288px;
		padding-bottom: 2rem;

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	._article-content>* {
		font-size: 1.2rem;
		margin: 0 0 1rem 0;
		font-weight: 700;

		&:hover {
			text-decoration: underline;
			color: $ft-sky-blue-3;
			font-weight: 700;
		}
	}
}

.privacy-disclaimer {
	margin-top: 1.5rem;
	font-size: 0.813rem;
	color: $font-color-secondary;

	a:hover{
		color: #33adff;
		text-decoration: underline;
	}
}

.main-content {
	display: flex;
	gap: 2rem;
}

.landing-heading {
	margin: 2rem 0;
}

.existing-button-disabled {
	cursor: not-allowed;
}