@import "../../theme/defs";

.page-headings {
  margin-bottom: $space-sm;
  background: $ft-sky-blue-4;
  color: $ft-light-grey;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(118, 118, 118, 0.15);
  padding: 0.75rem;

  .floating {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    // padding: 1rem;
    font-size: 1.3125rem;
  }
  .floating.sm {
    width: 40px;
    height: 40px;
    // padding: 7px;
  }
  .ft-split {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__first {
      display: flex;
    }

    &__last {
      text-align: end;
      margin-right: $space-sm;
    }
  }
  &__icon {
    background: $ft-white;
    color: $ft-sky-blue;
    margin-right: 0.75rem;

    &__text {
      height: 24px;
      font-size: $space-sm;
      border-radius: 8px;
      padding: 0.5rem;
      background: #fff;
      color: $ft-sky-blue;
      margin-right: 0.75rem;
    }

    > span {
      font-weight: 500;
      font-size: $h3-size;
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }
  }

  &__title {
    flex-grow: 1;
    display: inline-flex;
    color: $ft-white;
    margin-right: $space-xs;

    > * {
      margin: auto 0;
      font-size: $h3-size;
      font-weight: 500;
    }
  }
  &__step {
    color: $ft-sky-blue;
    > * {
      margin: auto 0;
      font-size: $h3-size;
      font-weight: 500;
    }
  }

  > * {
    font-weight: normal;
  }
}
