@import "src/theme/";

.summary-section {
  display: flex;
  justify-content: center;
  gap: 2rem;
  background: $ft-light-grey;
  padding: $space-md $space-xxl $space-xs;
  padding-left: $space-xxxl;
  .ftbox-container {
    max-width: calc($ft-container-width/2 - $space-xxl);
  }
}

.btn-text-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.3rem;
  i {
    padding-left: 0.5rem;
  }
}

.info-line {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 2rem;
}

.info-line-icon {
  color: #3769ff;
  margin: auto 0;
  flex-basis: 0;
  flex-grow: 0.05;
}

.info-line-label {
  flex-basis: 0;
  flex-grow: 0.25;
}

.info-line-data {
  flex-basis: 0;
  flex-grow: 0.7;
  font-weight: 700;
}

.steps-list {
  flex-wrap: wrap;
  display: flex;
  padding: 1.5rem 0 0.6rem;
  > div {
    display: flex;
    gap: 10px;
    margin-bottom: 1rem;
    flex: 50%;
  }
  > div > .icon {
    color: #fff;
    width: 2px;
    height: 2px;
    background-color: #1446e1;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid #e8e8e8;
    box-shadow: inset 0 0 0 2px #fff;
    margin: auto 0;
  }
  .steps-name {
    font-weight: 700;
  }
  .step-2 {
    order: 3;
  }
  .step-3 {
    order: 5;
  }
  .step-4 {
    order: 2;
  }
  .step-5 {
    order: 4;
  }
}

.floating-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  padding: 1rem;
  font-size: 1.3125rem;
}

.user-summary-footer {
  margin-top: 3.5rem;
}

.proposals-section {
  padding: 1.5rem 4.5rem 0.5rem;
  padding-left: 9rem;
  margin: 1rem auto;
  max-width: $ft-container-width-small;
}

.no-proposals-section {
  display: flex;
}

.no-proposals-content {
  margin: auto;
  h3 {
    width: 60%;
    font-weight: 500;
    line-height: 150%;
  }
}

.proposal-cards-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: $space-sm;
}

.proposal-card-container {
  flex: 0 0 33.3333%;
  padding: 0 $space-xs;
  max-width: 315px;
}

.proposal-card {
  display: flex;
  flex-direction: column;
  height: 581px;
  background-color: #00847d;
  box-shadow: 0 0 10px 1px #0000001a;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  span {
    font-size: $smallest-size;
  }
  .delete-proposal-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    span,
    i {
      cursor: pointer;
    }
  }
  .header {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1.5rem;
    color: #f4f4f4;
  }
  .body {
    flex: 5;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    color: #595959;
  }
  .row {
    flex: 1;
    display: flex;
    gap: 10px;
    align-items: center;
    border-bottom: 1px #8c8c8c solid;
    padding: 0 1.5rem;
    justify-content: space-between;

    &:last-child {
      border: none
    }
  }
  .label {
    display: flex;
    flex-direction: row;
    padding-top: 0.25rem;
    i {
      display: flex;
      align-items: center;
    }
    .label-name {
      font-size: 0.813rem;
      margin: auto auto auto 0;
      padding-left: 0.5rem;
    }
  }
  .value {
    display: inline-flex;
    justify-content: flex-end;
    text-align: right;
  }
}

.delete-proposal-container {
	text-align: left;
	width: 450px;
	max-width: 800px;
	height: initial;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: relative;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	outline: 0;
	box-shadow: 0 5px 15px #00000080;
	padding: 3.5rem;
	z-index: 1300;
}

.delete-proposal-footer {
	display: flex;
	align-items: center;
	gap: 1rem;
	padding-top: 2rem;
	.button-container {
		padding: 0;
	}
}

.modal-title {
	margin: 0;
	line-height: 1.428571429;
	color: #3769ff;
	i {
		font-size: 48px;
	}
}
