@import "../../theme/defs";

.toggle-container {
    margin-top: 10px;
}

.toggle:focus-within {
    outline: 2px solid rgb(26, 25, 25);
}

.toggle {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 21px;
    margin-left: 1.5rem;

    &-tooltip {
        height: fit-content;
        margin-left: $space-xs;
    }

    &__is-disabled {
        input+.slider {
            background-color: $ft-grey-3;
            cursor: not-allowed;
        }
    }

    &:not(.toggle__is-disabled) {
        .circle:hover {
            &::before {
                background: rgba(232, 232, 232, .5);
            }
        }

        input:active {
            .circle::before {
                background: rgba(167, 167, 167, .5);
            }
        }

        input:checked+.slider .circle:hover {
            &::before {
                background: rgba(219, 239, 251, .5);
            }
        }

        input:checked:active+.slider {
            .circle::before {
                background: rgba(165, 215, 245, .5);
            }
        }
    }
}

.toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $ft-grey-1;
    transition: .2s;
}

.slider .circle {
    position: absolute;
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: .2s;
    border-radius: 50%;

    &::before {
        content: "";
        width: 33px;
        height: 33px;
        position: absolute;
        left: -11px;
        bottom: -9px;
        background: transparent;
        border-radius: 50%;
    }
}

input:checked+.slider {
    background-color: $ft-brand-primary-blue;
}

input:focus+.slider {
    box-shadow: 0 0 1px $ft-brand-primary-blue;
}

input:checked+.slider .circle {
    transform: translateX(18px);

    &::before {
        left: -9px;
    }
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round::before {
    border-radius: 50%;
}