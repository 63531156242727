@import "./defs";

// FT theme colors
$ft-primary: #3769ff;
$primary: $ft-primary;
$ft-primary-focus: #1446e1;
$ft-primary-light: #007acc;
$ft-register-section-bg: #00847d;

// lengths and widths
$ft-container-width: 1220px;
$ft-container-width-small: 1100px;

// font color
$font-color-primary: "#333333";
$font-color-secondary: "#212121";

// box shadow
$card-box-shadow: 0 5px 10px #76767626;
$header-box-shadow: 0 9px 12px #00000014;
