@import "src/theme";

.signin-container {
  width: 60%;
  margin: 4rem 2rem 0;
}
.signin-input-block {
  display: flex;
  flex-direction: column;
  box-shadow: $card-box-shadow;
  padding: 1rem 3rem;
}
.help-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.register-section {
  background: $ft-register-section-bg;
  border-radius: 0 0 20px 20px;
  color: #fff;
  padding: 1rem 3.5rem;
  & p {
    margin: 0 0 0.3rem 0;
    font-size: 1.3rem;
  }
  & .link {
    text-decoration: underline;
    color: #fff;
  }
}

.bold-field {
  padding: 0.5rem 1rem;
}
