@import "./_ft-style-guide";

/// ====================================================================================================================
/// Default Client Domain Definitions File
/// -------------------------------------------------------
/// This file is not an output file, but imported in every scss file that is loaded by webpack as part of the build
/// process. This file and subsequent files that are imported must only contain definitions and no actual CSS that would
/// be output. Otherwise every SCSS file would get these CSS rules.
/// ====================================================================================================================

/// ============================================================================================================ ///
/// QF variables override
/// ============================================================================================================ ///

// TODO: fix or replace this after checking with FT designers
$ft-brand-primary-blue: $ft-sky-blue;
$ft-dark-blue: $ft-sky-blue-4;
$ft-red-1: #e60000;
$ft-disclaimer-text-color: $ft-black;

// Colors
$qf-color-primary: #007acc;
$qf-color-primary-background: $ft-brand-primary-blue;
$qf-color-secondary: #ddd;
$qf-color-neutral: #e8e8e8;
$qf-color-neutral-darker: darken($qf-color-neutral, 20%);
$qf-color-neutral-lighter: lighten($qf-color-neutral, 20%);
$qf-color-neutral-background: #fafaf9;
$qf-color-content-background: #fff;
$qf-color-neutral-border: #a4a4a4;
// Status
$qf-color-status-success: #3c763d;
$qf-color-status-danger: $ft-red-1;
$qf-color-status-warning: #e6a23c;
$qf-color-status-info: #007acc;
// Performance
$qf-color-performance-positive: #008000;
$qf-color-performance-negative: #f00;

// Text
$ft-text-color: #333;

// Spacing
$qf-spacing-inset: 24px;
$qf-spacing-inset-squash: 48px 24px;
$qf-spacing-stack-lg: 60px;

// Components

// Button
$qf-button-border-radius: 4px;

// Inputs
$qf-input-display: block;
$qf-input-border-radius: 0;
$qf-input-border: 1px solid #c9c9c9;
$qf-input-bg: #fff;
$qf-input-bg-hover: #fff;
$qf-input-label-color: #333;
$qf-input-line-height: 1.3em;
$qf-input-padding: 36px 16px 8px 16px;
// Focus state
$qf-input-state-focus-color: $qf-color-neutral-darker;
// Disable state
$qf-input-state-disabled-color: $qf-color-neutral;
$qf-input-state-disabled-bg: $qf-color-neutral-border;
// Selected state
$qf-input-state-selected-color: $qf-color-secondary;
// Box shadow in different state
$qf-input-box-shadow: none;
$qf-input-box-shadow-focus: none;
$qf-input-box-shadow-hover: none;
$qf-input-box-shadow-error: none;

// Dropdown
$qf-dropdown-list-option-bg-hover: #dbeffb;
$qf-input-state-selected-bg: #dbeffb;
// Text
$qf-text-color: #333;
// Divider
$qf-hr-color: $qf-color-primary;

// Toolbar
$qf-toolbar-page-header-nav-bg: white;
$qf-toolbar-page-header-nav-color: white;
$qf-toolbar-page-header-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
$qf-toolbar-page-header-nav-bar-height: 72px;

// Card
$qf-card-border-radius: 20px 20px 0 0;
$qf-card-standard-header-bg: $qf-color-primary-background;
$qf-card-standard-border: 0;

// Radio buttons
$qf-radio-group-option-size: 24px;
$qf-radio-group-option-icon-border-width: 2px;
$qf-input-state-selected-color: $ft-brand-primary-blue;
$qf-input-state-disabled-bg: $ft-brand-primary-blue;

// Check box
$qf-input-checkbox-checkmark-color: $ft-brand-primary-blue;
// $qf-input-bg-hover: $ft-brand-primary-blue;

// Popover
$qf-popover-box-bg: $ft-sky-blue;
$qf-popover-box-box-shadow: 0 4px 3px rgba(239, 239, 239, 0.25);
$qf-popover-icon-font-size: $p-size;
$qf-popover-color: $ft-sky-blue;
$qf-popover-min-width: 320px;
$qf-popover-font-size: 12px;
$qf-popover-box-border-radius: 10px;
$qf-popover-arrow-color: $ft-sky-blue;

// Input group
$qf-input-group-background: #fafafa;
/// ============================================================================================================ ///
/// QF Weblib Definitions
/// ============================================================================================================ ///
/// Import qf.defs at the bottom to allow the above variables override.
// @import '~@quantifeed/weblib/theme/_qf.defs';

/// ============================================================================================================ ///
/// Bootstrap default variables
/// ============================================================================================================ ///
/// Include bootstrap variables here, as component style file may require variables from bootstrap.
/// This bootstrap variables file use *qf-* variables. So please keep this after the main variables files.
// @import './vendors/bootstrap/_variables';
