@import "../../theme";

.line-chart {
    flex: 80%;
    pointer-events: none;

    &-description {
        flex: 20%;
        padding-left: $space-md;
    }

    &-container {
        margin-bottom: $space-md;
        display: flex;
    }
}

h5 {
    color: #000;
}
